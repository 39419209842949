/* @flow */
import airFreightReportHeader, { airFreightReportHiddenHeader } from '../../constants/airFreightReportHeader';

export const getFieldsList = (airFreightSecondaryFields: Object) => {
  airFreightReportHeader
    .filter((header) => header.default)
    .forEach((header) => {
      airFreightSecondaryFields.push(header.primary);
      if (header.secondary) {
        airFreightSecondaryFields.push(header.secondary);
      }
      if (header.additionalFields) {
        airFreightSecondaryFields.push(...header.additionalFields);
      }
      if (header.includeInRequest) {
        airFreightSecondaryFields.push(header.primary);
      }
    });
  // to add dpomAirFreight.isLocked in fields with and without overide eligible check
  airFreightReportHiddenHeader.forEach((headerRow: Object) => {
    if (headerRow.includeInRequest) {
      airFreightSecondaryFields.push(headerRow.primary);
    }
  });
};

export const searchNewFieldOnSavedSearch = (requestData: Object) => {
  if (Array.isArray(requestData)
    && !requestData.map((field) => field.fieldName === 'dpomAirFreight').some((value) => value === true)
  ) {
    requestData.push(
      {
        fieldName: 'dpomAirFreight',
        function: 'IS_DEFINED'
      }
    );
  }

  return requestData;
};

// export const getFieldsForRequest = (
//   state: Object,
//   selectedColumnOrderOptions: Object,
//   fields: Object
// ) => (checkForColumnData(state, true) ? selectedColumnOrderOptions : fields);

// export const getSelectedFilterValues = (filterValues: Object) => filterValues && filterValues
//   .map((filter) => {
//     const filterInResponse = {};
//     const fieldProperties = poSearchFieldProperties[filter] || {};
//     if (!fieldProperties.primary) {
//       return null;
//     }
//     filterInResponse.primary = fieldProperties.primary;
//     if (fieldProperties.secondary) {
//       filterInResponse.secondary = fieldProperties.secondary;
//     }
//     return filterInResponse;
//   });
//   // .filter((element) => element);
// export const getModifiedSelectedFilters = (
//   filtersSelected: Object,
//   filter: string,
//   modifiedSelectedFilters: Object
// ) => {
//   const modifiedSelectedFiltersNew = modifiedSelectedFilters;
//   if (filtersSelected.includes(filter)) {
//     if (!modifiedSelectedFilters[filter]) modifiedSelectedFiltersNew[filter] = [];
//   } else {
//     modifiedSelectedFiltersNew[filter] = null;
//   }
// };
// export function getSelectedFilterKeys(
//   filter: string,
//   modifiedSelectedFilters: Object,
//   filterDropdownValues: Object,
//   searchFiltersToBeRequested: Object,
//   filtersInResponse: Object
// ) {
//   const filterRequest: { primary: string; secondary?: string; } = { primary: filter };
//   if (poSearchFieldProperties[filter] && poSearchFieldProperties[filter].secondary) {
//     filterRequest.secondary = poSearchFieldProperties[filter].secondary;
//   }
//   if (modifiedSelectedFilters[filter]) {
//     if (!filterDropdownValues[filter]) {
//       searchFiltersToBeRequested.push(filterRequest);
//     }
//     filtersInResponse.push(filterRequest);
//   }
// }

// export const getFilterInResponseValueInPOSearch = (selectedFilterKeys: Object) => (
//   selectedFilterKeys.map((filter) => {
//     const filterInResponse = {};
//     const fieldProperties = poSearchFieldProperties[filter] || {};
//     if (!fieldProperties) {
//       return null;
//     }
//     filterInResponse.primary = fieldProperties.primary;
//     if (fieldProperties.secondary) {
//       filterInResponse.secondary = fieldProperties.secondary;
//     }
//     return filterInResponse;
//   })
//     .filter((element) => element)
// );

// export const getFiltersInResponse = (selectedFilterOptions: Object,
//   filterValuesOptByUser: Object,
//   state: Object) => (selectedFilterOptions?.length
//   ? filterValuesOptByUser
//   : getFiltersInResponseRequestBody(state.PoSearch.selectedFilters));

// export function getFilterData(filtersInResponse: Array<Object>): Array<Object> {
//   return [...new Set(filtersInResponse.map(({ primary }: { primary: string }) => primary))]
//     .map((primary: string) => filtersInResponse.find(
//       ({ primary: p }: { primary: string }) => p === primary
//     ));
// }
// export const setSchedulerData = (
//   schedulerResponse: Object,
//   savedSearchData: Object
// ) => {
//   const schedulerData = [];
//   schedulerResponse.data.objects.forEach((item) => {
//     const { saveSearchID } = item;
//     if (item?.executeOnce) {
//       schedulerData[saveSearchID] = {
//         id: item.id,
//         saveSearchID: item.saveSearchID,
//         scheduleID: item.scheduleID,
//         startTime: item.startTime,
//         state: item.state
//       };
//     } else {
//       schedulerData[saveSearchID] = item;
//     }
//   });
//   const newObj = savedSearchData.data.objects.map((item) => {
//     const savedSearchItem = item;
//     if (schedulerData[item.id]) {
//       savedSearchItem.schedulerData = schedulerData[item.id];
//     }
//     return savedSearchItem;
//   });
//   return newObj;
// };
