/**
 * @flow
 */
export default {
  poNumber: {
    primary: 'poNumber', secondary: '', type: 'string', link: false, label: 'PO Number', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  lineItemNumber: {
    primary: 'itemNumber', secondary: '', type: 'string', link: false, label: 'Line Item  Number', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  user: {
    primary: 'requestedByEmailID', secondary: '', type: 'string', link: false, label: 'Requestor', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  user1: {
    primary: 'requestedByUserID', secondary: '', type: 'string', link: false, label: 'Requestor User', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  userFactory: {
    primary: 'userFactory', secondary: '', type: 'string', link: false, label: 'User Factory', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  proposedNAF: {
    primary: 'proposedNAF%',
    secondary: '',
    type: 'string',
    link: false,
    label: 'Suggested NAF%',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    default: true
  },
  proposedFAF: {
    primary: 'proposedFAF%', secondary: '', type: 'string', link: false, label: 'Suggested FAF%', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  requestorComments: {
    primary: 'requestorComments', secondary: '', type: 'string', link: false, label: 'Requestor Comment', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  comments1: {
    primary: 'comments', secondary: '', type: 'string', link: false, label: 'Comments', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  approverComments: {
    primary: 'approverComments', secondary: '', type: 'string', link: false, label: 'Approver Comment', wrap: true, numeric: 'false', disablePadding: false, group: true
  },
  overrideStatus: {
    primary: 'overrideStatus', secondary: '', type: 'string', link: false, label: 'Status', wrap: true, numeric: 'false', disablePadding: false, group: true, gridSpan: 2
  },
  attachments: {
    primary: 'attachments', secondary: '', type: 'string', link: false, label: 'Attachments', wrap: true, numeric: 'true', disablePadding: false, width: 180, isEditable: false
  },
  geoCode: {
    primary: 'geoCode', secondary: 'geoDescription', type: 'string', link: false, label: 'GEO Code', wrap: true, numeric: 'true', disablePadding: false, width: 180, isEditable: false
  },
  pmoCode: {
    primary: 'productionNikeLiaisonOffice', secondary: 'pmoDescrition', type: 'string', link: false, label: 'PMO/DEC Code', wrap: true, numeric: 'true', disablePadding: false, width: 180, isEditable: false
  },
  actualNAF: {
    primary: 'currentNAF%', secondary: '', type: 'string', link: false, label: 'Actual NAF%', wrap: true, numeric: 'true', disablePadding: false, width: 180, isEditable: false
  },
  actualFAF: {
    primary: 'currentFAF%', secondary: '', type: 'string', link: false, label: 'Actual FAF%', wrap: true, numeric: 'true', disablePadding: false, width: 180, isEditable: false
  }
};
