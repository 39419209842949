/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import {
  toggleSpinner, updateDialogStates, togglePaginationSpinner
} from '../dialogStates/dialogStatesSlice';
import { initialPPMReportFieldsState, ppmReportFieldList } from '../../constants/staticData';
import {
  checkForColumnData,
  getFilterValuesFromResponse, mapInputFieldsToRequest, mapRequestToInputFields,
  mapFiltersToRequest,
  sortedFilterData,
  setFieldItemVas,
  setFilterAsiaSize,
  setValidFields
} from '../../helpers/dataHelpers';
import getflattenResponse, { cryptoRand } from '../../helpers/common';
import appConfig from '../../appConfig';
import PPMSearchDataMock from '../mock/PPMSearchDataMock';
import serviceAPI from '../../helpers/serviceAPI';
import { showForNONPROD } from '../../helpers/envServer';
import ppmReportsHeader from '../../constants/ppmReportsHeader';
import ppmReportFieldMapping from '../../constants/fieldMappings/ppmReportFieldMapping';
import ppmReportFieldProperties from '../../constants/fieldProperties/ppmReportFieldProperties';
import {
  getFieldsList, getFiltersInResponseRequestBody, getRequestedFilterValues,
  getSelectedFilterValues, getSelectedFilterValuesFromOpMetrics,
  getFiltersForRequest, getModifiedSelectedFilters, getSelectedFilterKeys,
  asyncCallToNewFiltersCallback
} from './ppmHelper';
import { settingsActions } from '../profileSettings/settingSlice';
import { removeFieldAsiaSize, setFieldAsiaSize } from '../poSearch/poSearchHelper';

export const name = 'ppmReport';

function createInitialState() {
  return {
    isSavedSearchModified: false,
    ppmReportRequestBody: null,
    ppmReportInputFieldData: { ...initialPPMReportFieldsState },
    ppmReportSelectedFields: [],
    ppmReportBookmarkName: '',
    ppmReportBookmarkId: '',
    requestError: false,

    ppmReportResultData: {},
    ppmReportSearchId: '',
    ppmReportTotalCount: 0,
    ppmReportNextResults: {},
    ppmReportPrevResults: {},
    page: 0,
    rowsPerPage: 100,
    ppmReportSearchPanelId: '',

    ppmReportFilterId: 'none',
    filterDropdownValues: {},
    selectedFilters: showForNONPROD ? {
      [ppmReportFieldMapping.PPM_REPORT_ITEM_STATUS]: null,
      [ppmReportFieldMapping.PPM_REPORT_PLANNING_PRIORITY_NUMBER]: null,
      // [ppmReportFieldMapping.PPM_REPORT_SEASON_CODE]: null,
      [ppmReportFieldMapping.PPM_REPORT_TRANSPORT_MODE]: null,
      [ppmReportFieldMapping.PPM_REPORT_PLANT_CODE]: null,
      [ppmReportFieldMapping.PPM_REPORT_CUSTOMER_SHIP_TO]: null,
      [ppmReportFieldMapping.PPM_REPORT_LAUNCH]: null,
      [ppmReportFieldMapping.PPM_REPORT_SUB_CATEGORY_CODE]: null,
      [ppmReportFieldMapping.PPM_REPORT_SIZE_MISMATCH_ID]: null,
      [ppmReportFieldMapping.PPM_REPORT_DESTINATION_COUNTRY_CODE]: null,
      [ppmReportFieldMapping.PPM_REPORT_PO_REJECTION_CODE]: null,
      [ppmReportFieldMapping.PPM_REPORT_OVERDUE_INDICATOR]: null,
      [ppmReportFieldMapping.PPM_REPORT_TITLE_TRANSFER_MODEL_CODE]: null,
      // [ppmReportFieldMapping.PPM_REPORT_SEASON_YEAR]: null,
      [ppmReportFieldMapping.PPM_REPORT_FOB_MISMATCH_INDICATOR]: null,
      [ppmReportFieldMapping.PPM_REPORT_USECASE_CODE]: null,
      [ppmReportFieldMapping.PPM_REPORT_ASIAN_CC]: null
    } : {
      [ppmReportFieldMapping.PPM_REPORT_ITEM_STATUS]: null,
      [ppmReportFieldMapping.PPM_REPORT_PLANNING_PRIORITY_NUMBER]: null,
      // [ppmReportFieldMapping.PPM_REPORT_SEASON_CODE]: null,
      [ppmReportFieldMapping.PPM_REPORT_TRANSPORT_MODE]: null,
      [ppmReportFieldMapping.PPM_REPORT_PLANT_CODE]: null,
      [ppmReportFieldMapping.PPM_REPORT_CUSTOMER_SHIP_TO]: null,
      [ppmReportFieldMapping.PPM_REPORT_LAUNCH]: null,
      [ppmReportFieldMapping.PPM_REPORT_SUB_CATEGORY_CODE]: null,
      [ppmReportFieldMapping.PPM_REPORT_SIZE_MISMATCH_ID]: null,
      [ppmReportFieldMapping.PPM_REPORT_DESTINATION_COUNTRY_CODE]: null,
      [ppmReportFieldMapping.PPM_REPORT_PO_REJECTION_CODE]: null,
      [ppmReportFieldMapping.PPM_REPORT_OVERDUE_INDICATOR]: null,
      [ppmReportFieldMapping.PPM_REPORT_TITLE_TRANSFER_MODEL_CODE]: null,
      // [ppmReportFieldMapping.PPM_REPORT_SEASON_YEAR]: null,
      [ppmReportFieldMapping.PPM_REPORT_FOB_MISMATCH_INDICATOR]: null,
      [ppmReportFieldMapping.PPM_REPORT_USECASE_CODE]: null,
      [ppmReportFieldMapping.PPM_REPORT_ASIAN_CC]: null
    }
  };
}

export const initialState = createInitialState();

function createReducers() {
  function updateReportFiltersRequest(state: Object, action: Object) {
    const updatedBody = { ...state.ppmReportRequestBody, filter: action.payload };
    return {
      ...state,
      ppmReportRequestBody: updatedBody,
      isSavedSearchModified: Boolean(state.ppmReportBookmarkName)
    };
  }
  // ppmReportResults
  function updatePPMPage(state: Object, action: Object) {
    return {
      ...state,
      page: action.payload?.page ? action.payload?.page : action.payload
    };
  }

  function updatePPMRowsPerPage(state: Object, action: Object) {
    return {
      ...state,
      rowsPerPage: action.payload?.page ? action.payload?.page : action.payload
    };
  }

  function updatePPMReportRequestBody(state: Object, action: Object) {
    const requestExist = JSON.stringify(state.ppmReportRequestBody);
    return {
      ...state,
      ppmReportRequestBody: action.payload,
      isSavedSearchModified: Boolean(
        state.ppmReportBookmarkId && requestExist !== JSON.stringify(action.payload)
      )
    };
  }

  function updateIsSavedSearchModified(state: Object, action: Object) {
    return {
      ...state,
      isSavedSearchModified: Boolean(action.payload)
    };
  }

  function updatePPMReportRequestBodyOnSameBookmark(state: Object, action: Object) {
    return {
      ...state,
      ppmReportRequestBody: action.payload
    };
  }

  function clearFilters(state: Object) {
    const selectedFilters = {};
    Object.keys(state.selectedFilters).forEach((filter) => {
      selectedFilters[filter] = null;
    });
    return {
      ...state,
      filterDropdownValues: {},
      selectedFilters
    };
  }

  function resetPPMReportResults(state: Object) {
    return {
      ...state,
      ppmReportResultData: {},
      ppmReportSearchId: '',
      ppmReportTotalCount: 0,
      ppmReportNextResults: {},
      ppmReportPrevResults: {},
      page: 0,
      rowsPerPage: 100,
      ppmReportSearchPanelId: ''
    };
  }

  function resetPPMReportRequest(state: Object) {
    return {
      ...state,
      isSavedSearchModified: false,
      ppmReportRequestBody: null,
      ppmReportInputFieldData: { ...initialPPMReportFieldsState },
      ppmReportSelectedFields: [],
      ppmReportBookmarkName: '',
      ppmReportBookmarkId: '',
      requestError: false
    };
  }

  function resetPPMReportRequestForClearSearch(state: Object) {
    return {
      ...state,
      isSavedSearchModified: false,
      ppmReportRequestBody: null,
      ppmReportInputFieldData: { ...initialPPMReportFieldsState },
      ppmReportBookmarkName: '',
      ppmReportBookmarkId: '',
      requestError: false
    };
  }

  function updatePPMReportOffsetInRequest(state: Object, action: Object) {
    const updatedBody = { ...state.ppmReportRequestBody, offset: action.payload };
    return { ...state, ppmReportRequestBody: updatedBody };
  }

  function updatePPMReportFields(state: Object, action: Object) {
    return {
      ...state,
      ppmReportSelectedFields: action.payload
    };
  }

  function updatePPMReportInputValues(state: Object, action: Object) {
    return {
      ...state,
      ppmReportInputFieldData: action.payload
    };
  }

  function updateCollabBookmarkId(state: Object, action: Object) {
    return {
      ...state,
      isSavedSearchModified: false,
      ppmReportBookmarkId: action.payload
    };
  }

  function updateCollabBookmarkName(state: Object, action: Object) {
    return {
      ...state,
      isSavedSearchModified: false,
      ppmReportBookmarkName: action.payload
    };
  }

  function updateReportFilterDropDownValues(state: Object, action: Object) {
    if (
      action.payload
      && Object.keys(action.payload).length > 0
    ) {
      return {
        ...state,
        filterDropdownValues: sortedFilterData(action.payload)
      };
    }
    return { ...state };
  }

  function bulkUpdateSelectedPPMFilters(state: Object, action: Object) {
    if (action.payload instanceof Array) {
      const modifiedSelectedFilters = { ...state.selectedFilters };
      Object.keys(modifiedSelectedFilters).forEach((filter: string) => {
        if (action.payload.includes(filter)) {
          if (!modifiedSelectedFilters[filter]) modifiedSelectedFilters[filter] = [];
        } else {
          modifiedSelectedFilters[filter] = null;
        }
      });
      return { ...state, selectedFilters: modifiedSelectedFilters };
    }
    if ((typeof action.payload === 'object') && action.payload.selectedFilters
      && Object.keys(action.payload.selectedFilters).length > 0) {
      const modifiedSelectedFilters = {
        ...state.selectedFilters,
        ...action.payload.selectedFilters
      };
      return { ...state, selectedFilters: modifiedSelectedFilters };
    }
    return { ...state, selectedFilters: initialState.selectedFilters };
  }

  function updatePPMReportResults(state: Object, action: Object) {
    return {
      ...state,
      ppmReportResultData: action.payload,
      ppmReportSearchId: cryptoRand().toString().substr(2, 8),
      ppmReportTotalCount: action.payload.pages.totalResources,
      selectedRecordsCount: 0,
      ppmReportNextResults: null,
      ppmReportPrevResults: null
    };
  }

  function updatePPMReportSearchId(state: Object) {
    return { ...state, ppmReportSearchId: cryptoRand().toString().substr(2, 8) };
  }

  function resetPPMReportSearchPanelId(state: Object) {
    return { ...state, ppmReportSearchPanelId: cryptoRand().toString().substr(2, 8) };
  }

  function resetPPMReportInputData(state: Object) {
    return {
      ...state,
      ppmReportInputFieldData: { ...initialPPMReportFieldsState }
    };
  }

  function resetPPMReportSelectedFiltersToInitialState(state: Object) {
    const selectedFilters = {};
    Object.keys(state.selectedFilters).forEach((filter) => {
      if (state.selectedFilters[filter]) {
        selectedFilters[filter] = [];
      } else {
        selectedFilters[filter] = null;
      }
    });
    return {
      ...state,
      selectedFilters
    };
  }

  function updatePPMReportNextResult(state: Object, action: Object) {
    const payload = {
      data: getflattenResponse(action.payload.currentResultSet),
      dataNext: getflattenResponse(action.payload.response.data)
    };

    return {
      ...state,
      ppmReportResultData: payload.data,
      ppmReportNextResults: payload.dataNext,
      ppmReportTotalPages: payload.data.pages.totalPages,
      ppmReportTotalCount: payload.data.pages.totalResources
    };
  }

  function updatePPMReportPrevResult(state: Object, action: Object) {
    const payload = {
      data: getflattenResponse(action.payload.currentResultSet),
      dataPrev: getflattenResponse(action.payload.response.data)
    };

    return {
      ...state,
      ppmReportResultData: payload.data,
      ppmReportPrevResults: payload.dataPrev,
      ppmReportTotalPages: payload.data.pages.totalPages,
      ppmReportTotalCount: payload.data.pages.totalResources
    };
  }

  function clearSavedPPMReport(state: Object) {
    return {
      ...state,
      isSavedSearchModified: false,
      ppmReportBookmarkId: '',
      ppmReportBookmarkName: ''
    };
  }

  function ppmReportOffset(state: Object, action: Object) {
    return {
      ...state,
      ppmReportResultData: action.payload,
      ppmReportTotalCount: action.payload.pages.totalResources,
      selectedRecordsCount: 0,
      ppmReportNextResults: null,
      ppmReportPrevResults: null
    };
  }

  function appendNextResultSet(state: Object) {
    const payload = {
      dataPrev: state.ppmReportResultData,
      data: state.ppmReportNextResults
    };

    return {
      ...state,
      ppmReportResultData: payload.data,
      ppmReportPrevResults: payload.dataPrev,
      ppmReportNextResults: null
    };
  }

  function appendPrevResultSet(state: Object) {
    const payload = {
      data: state.ppmReportPrevResults,
      dataNext: state.ppmReportResultData
    };

    return {
      ...state,
      ppmReportResultData: payload.data,
      ppmReportNextResults: payload.dataNext,
      ppmReportPrevResults: null
    };
  }

  // ppmReportResults -- end

  function setPpmReportFilterId(state: Object) {
    return {
      ...state,
      ppmReportFilterId: cryptoRand().toString().substr(2, 8)
    };
  }

  function bulkUpdateReportFilterValues(state: Object, action: Object) {
    if (!action.payload.filter) return { ...state };
    const modifiedSelectedFilters = {
      ...state.selectedFilters,
      [action.payload.filter]: action.payload.values
    };
    return { ...state, selectedFilters: modifiedSelectedFilters };
  }

  function bulkToggleFilters(state: Object, action: Object) {
    const modifiedSelectedFilters = { ...state.selectedFilters };
    Object.keys(action.payload).forEach((filter) => {
      if (action.payload[filter]) {
        if (!modifiedSelectedFilters[filter]) modifiedSelectedFilters[filter] = [];
      } else modifiedSelectedFilters[filter] = null;
    });
    return { ...state, selectedFilter: modifiedSelectedFilters };
  }
  // Toolkit: TODO
  function updateReportFiltersInResposne(state: Object, action: Object) {
    // UPDATE_PPM_REPORT_FILTERSINRESPONSE_IN_REQUEST
    const updatedBody = { ...state.ppmReportRequestBody, filtersInResponse: action.payload };
    return { ...state, ppmReportRequestBody: updatedBody };
  }

  // function updateReportFiltersInResposne(state: Object, action: Object) {
  //   // UPDATE_PPM_REPORT_FILTERSINRESPONSE_IN_REQUEST
  //   const updatedBody = { ...state.body, filtersInResponse: action.payload };
  //   return { ...state, body: updatedBody };
  // }

  function bulkUpdateSelectedFilters(state: Object, action: Object) {
    if (action.payload instanceof Array) {
      const modifiedSelectedFilters = { ...state.selectedFilters };
      Object.keys(modifiedSelectedFilters).forEach((filter: string) => {
        if (action.payload.includes(filter)) {
          if (!modifiedSelectedFilters[filter]) modifiedSelectedFilters[filter] = [];
        } else {
          modifiedSelectedFilters[filter] = null;
        }
      });
      return { ...state, selectedFilters: modifiedSelectedFilters };
    }
    if ((typeof action.payload === 'object') && action.payload.selectedFilters
      && Object.keys(action.payload.selectedFilters).length > 0) {
      const modifiedSelectedFilters = {
        ...state.selectedFilters,
        ...action.payload.selectedFilters
      };
      return { ...state, selectedFilters: modifiedSelectedFilters };
    }
    return { ...state, selectedFilters: initialState.selectedFilters };
  }

  function RESET_PPM_REPORT_FILTER_DROPDOWN_VAUES(state: Object) {
    return {
      ...state,
      filterDropdownValues: {}
    };
  }

  return {
    updatePPMReportInputValues,
    updatePPMReportFields,
    updatePPMReportRequestBody,
    updateIsSavedSearchModified,
    updatePPMReportRequestBodyOnSameBookmark,
    updateCollabBookmarkId,
    updateCollabBookmarkName,
    resetPPMReportRequest,
    resetPPMReportInputData,
    clearSavedPPMReport,
    resetPPMReportRequestForClearSearch,
    updatePPMReportOffsetInRequest,
    // updateReportFiltersInResposne,
    updateReportFiltersRequest,

    updatePPMPage,
    updatePPMRowsPerPage,
    resetPPMReportResults,
    updatePPMReportResults,
    updatePPMReportSearchId,
    resetPPMReportSearchPanelId,
    updatePPMReportNextResult,
    updatePPMReportPrevResult,
    ppmReportOffset,
    appendNextResultSet,
    appendPrevResultSet,

    setPpmReportFilterId,
    updateReportFilterDropDownValues,
    bulkUpdateReportFilterValues,
    bulkToggleFilters,
    updateReportFiltersInResposne,
    bulkUpdateSelectedFilters,
    clearFilters,
    resetPPMReportSelectedFiltersToInitialState,
    RESET_PPM_REPORT_FILTER_DROPDOWN_VAUES,
    bulkUpdateSelectedPPMFilters
  };
}

export const reducers = createReducers();
export const slice = createSlice({ name, initialState, reducers });

const actions = { ...slice.actions };

function createExtraActions() {
  // ppmReportResults -- start
  function callPPMReportSearchAPI() {
    return createAsyncThunk(
      `${name}/callPPMReportSearchAPI`,
      async ({
        requestBody,
        rowsPerPageOption,
        filterOptions,
        shouldRequestFilterValues,
        callback
      }, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));
        let payload = { ...requestBody };
        payload = setValidFields(ppmReportFieldProperties, payload);
        payload = setFieldItemVas(payload);
        payload = setFieldAsiaSize(payload);
        payload = setFilterAsiaSize(payload);
        const requestData = {
          ...payload,
          fields: [
            ...payload.fields,
            ...appConfig.includeFieldsPPM.filter(
              (item) => !payload.fields.includes(item)
            )
          ]
        };
        if (serviceAPI('ppmReportSearch').mock) {
          dispatch(actions.updatePPMReportRequestBody(payload));
          dispatch(toggleSpinner(false));
          dispatch(actions.updatePPMRowsPerPage(rowsPerPageOption));
          dispatch(actions.updatePPMReportResults(PPMSearchDataMock.data));
        } else {
          request({
            api: 'ppmReportSearch',
            method: 'post',
            data: requestData,
            cancellable: true
          }, dispatch, getState).then((response: Object) => {
            payload = removeFieldAsiaSize(payload);
            dispatch(toggleSpinner(false));
            dispatch(actions.updatePPMReportRequestBody(payload));
            callback(response.data);
            dispatch(actions.updatePPMRowsPerPage(rowsPerPageOption));
            if (shouldRequestFilterValues) {
              dispatch(actions.updateReportFilterDropDownValues(
                getFilterValuesFromResponse(response.data.filters || [])
              ));
            }
            if (shouldRequestFilterValues && (filterOptions && filterOptions.length > 0)) {
              dispatch(actions.bulkUpdateSelectedPPMFilters(filterOptions));
            }
            dispatch(actions.resetPPMReportSearchPanelId());
            dispatch(actions.updatePPMReportResults(
              getflattenResponse(response.data)
            ));
          }).catch((error: Object) => {
            console.log('Error: ', error);
            dispatch(toggleSpinner(false));
            callback(null, error);
          });
        }
      }
    );
  }

  function fetchPOReports() {
    return createAsyncThunk(
      `${name}/fetchPOReports`,
      async ({
        data, callback, modifiedrequestBody = {},
        shouldRequestFilterValues = true, ppmCallPPMReportSearchAPI
      }, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));

        const state = getState();
        const secondaryFields = [];
        getFieldsList(secondaryFields);
        let requestData = {};
        const {
          selectedColumnOrderOptions,
          selectedFilterOptions: filterOptions,
          rowPerPageOption: rowsPerPageOption
        } = state.searchpaneldata || {};

        let columnOrderOptions = [...selectedColumnOrderOptions];

        if (checkForColumnData(state, true)) {
          ppmReportsHeader.forEach((headRow: Object) => {
            if (headRow.includeInRequest) {
              columnOrderOptions = [...columnOrderOptions, headRow.primary];
            }
          });
        }

        const fieldsForRequest = checkForColumnData(state, true)
          ? columnOrderOptions
          : secondaryFields;

        if (data) {
          requestData = mapInputFieldsToRequest(data, 'ppmReport');
          requestData.push({
            fieldName: ppmReportFieldMapping.PPM_REPORT_DELETION_INDICATOR,
            function: 'NOT IS_DEFINED'
          });
          const appliedSearchField = [...new Set(requestData.map((field) => field.fieldName))];
          dispatch(actions.updatePPMReportInputValues(data));
          dispatch(actions.updatePPMReportFields(ppmReportFieldList
            .map((field) => field.key)
            .filter((field) => appliedSearchField.includes(field))));
        } else {
          requestData = state.ppmReport.ppmReportRequestBody.search;
        }

        const filterRequest = Object.keys(modifiedrequestBody).length
          ? state.ppmReport.ppmReportRequestBody.filter
          : [];
        const requestBody = (state.ppmReport.ppmReportRequestBody) ? {
          ...state.ppmReport.ppmReportRequestBody,
          search: requestData,
          filter: filterRequest,
          offset: '0',
          ...modifiedrequestBody
        } : {
          fields: fieldsForRequest,
          search: requestData,
          filter: filterRequest,
          count: appConfig.SearchThresholdLimit,
          offset: '0',
          ...modifiedrequestBody
        };
        if (!filterOptions || !filterOptions.length) {
          if (shouldRequestFilterValues) {
            getRequestedFilterValues(requestBody, state);
          }
        } else {
          getSelectedFilterValues(requestBody, state);
        }

        dispatch(ppmCallPPMReportSearchAPI({
          requestBody,
          rowsPerPageOption,
          filterOptions,
          shouldRequestFilterValues,
          callback
        }));
      }
    );
  }

  function exportPOReport() {
    return createAsyncThunk(
      `${name}/exportPOReport`,
      async ({
        callback,
        filetype
      }, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));

        const state = getState();
        const searchRequestBody = {
          ...state.ppmReport.ppmReportRequestBody
        };
        let requestBody = {
          search: [...(searchRequestBody.search) || []],
          fields: [...(searchRequestBody.fields) || []].filter((field: string) => (
            !appConfig.excludedPPMReportFieldsInExportedFile.includes(field)
          )),
          filter: [...(searchRequestBody.filter) || []],
          searchType: searchRequestBody.searchType,
          fileFormat: filetype
        };
        const filteredFieldsData = [...requestBody.fields].filter(
          (item) => !item.toLowerCase().includes('ratetypename')
        );
        requestBody = { ...requestBody, fields: filteredFieldsData };
        requestBody = setFieldAsiaSize(requestBody);
        requestBody = setFilterAsiaSize(requestBody);

        request({
          api: 'ppmReportExportLargeFile',
          method: 'post',
          data: requestBody
        }, dispatch, getState).then((response: Object) => {
          dispatch(toggleSpinner(false));
          callback(response);
        }).catch((error: Object) => {
          console.log('Error: ', error);
          dispatch(toggleSpinner(false));
          callback(null, error);
        });
      }
    );
  }

  function callPPMReportSavedSearchAPI() {
    return createAsyncThunk(
      `${name}/callPPMReportSavedSearchAPI`,
      async ({
        savedSearchRequestBody,
        requestBody,
        ppmReportRequestData,
        rowsPerPageOption,
        filterOptions,
        callback
      }, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));

        let payload = { ...savedSearchRequestBody };
        const filteredFieldsData = [...savedSearchRequestBody.fields].filter(
          (item) => !item.toLowerCase().includes('ratetypename')
        );
        payload = { ...savedSearchRequestBody, fields: filteredFieldsData };
        payload = setValidFields(ppmReportFieldProperties, payload);
        payload = setFieldItemVas(payload);
        payload = setFieldAsiaSize(payload);
        payload = setFilterAsiaSize(payload);

        const requestData = {
          ...payload,
          fields: [
            ...payload.fields,
            ...appConfig.includeFieldsPPM.filter(
              (item) => !payload.fields.includes(item)
            )
          ]
        };
        request({
          api: 'ppmReportSearch',
          method: 'post',
          data: requestData,
          cancellable: true
        }, dispatch, getState).then((response: Object) => {
          const state = getState();
          const fields = mapRequestToInputFields([...ppmReportRequestData.searchCriteria.search]);
          const ppmReportSearchFields = [...new Set(
            ppmReportRequestData.searchCriteria.search.map((field: Object) => field.fieldName)
          )];
          dispatch(actions.updatePPMReportInputValues({
            ...fields
          }));
          let updatedRequestBody = setValidFields(ppmReportFieldProperties, requestBody);
          updatedRequestBody = setFieldItemVas(updatedRequestBody);
          updatedRequestBody = removeFieldAsiaSize(updatedRequestBody);
          dispatch(actions.updatePPMReportRequestBody(updatedRequestBody));
          dispatch(actions.updatePPMReportFields(ppmReportFieldList
            .map((field: Object) => field.key)
            .filter((field: Object) => ppmReportSearchFields.includes(field))));
          dispatch(actions.updatePPMReportResults(
            getflattenResponse(response.data)
          ));
          dispatch(actions.updatePPMReportSearchId());
          dispatch(actions.updatePPMPage(0));
          dispatch(actions.updatePPMRowsPerPage(rowsPerPageOption));
          dispatch(actions.updateCollabBookmarkId(ppmReportRequestData.id));
          dispatch(actions.updateCollabBookmarkName(ppmReportRequestData.name));
          dispatch(toggleSpinner(false));
          const filterValuesFromResponse = getFilterValuesFromResponse(response.data.filters || []);
          dispatch(actions.updateReportFilterDropDownValues({
            ...state.ppmReport.filterDropdownValues,
            ...filterValuesFromResponse
          }));
          const selectedFilters = {};
          const filters = ppmReportRequestData?.searchCriteria?.filter;
          if (filters && filters.length > 0) {
            filters.forEach((filter: Object) => {
              const availableFilters = filterValuesFromResponse[filter.fieldName];
              selectedFilters[filter.fieldName] = filter.fieldValue
                .map((filterValue: Object) => availableFilters
                  .find((availableFilter: Object) => availableFilter.includes(filterValue)))
                .filter((filterValue: Object) => filterValue);
            });
            dispatch(actions.bulkUpdateSelectedPPMFilters({
              selectedFilters
            }));
          } else {
            dispatch(actions.bulkUpdateSelectedPPMFilters({
              selectedFilters
            }));
          }

          if (filterOptions.length > 0) {
            dispatch(actions.bulkUpdateSelectedPPMFilters(filterOptions));
          }
          dispatch(toggleSpinner(false));
          dispatch(updateDialogStates({ executeBookmarkSpinner: false }));
          if (callback) {
            callback(response);
          }
        }).catch((error: Object) => {
          console.log('Error: ', error);
          dispatch(toggleSpinner(false));
          dispatch(updateDialogStates({ executeBookmarkSpinner: false }));
          if (callback) {
            callback(null, error);
          }
        });
      }
    );
  }

  function fetchSavedPPMReportResults() {
    return createAsyncThunk(
      `${name}/fetchSavedPPMReportResults`,
      async (
        { ppmReportRequestData, callback, ppmcallPPMReportSavedSearchAPI },
        { getState, dispatch }
      ) => {
        const requestBody = { ...ppmReportRequestData.searchCriteria, offset: '0' };
        if (requestBody.search
          && requestBody.search.map
          && !requestBody.search.find(
            (criteria) => criteria.fieldName === ppmReportFieldMapping.PPM_REPORT_DELETION_INDICATOR
          )
        ) {
          requestBody.search.push({
            fieldName: ppmReportFieldMapping.PPM_REPORT_DELETION_INDICATOR,
            function: 'NOT IS_DEFINED'
          });
        }

        const state = getState();
        const {
          selectedFilterOptions: filterOptions,
          rowPerPageOption: rowsPerPageOption
        } = state.searchpaneldata || {};
        dispatch(toggleSpinner(true));
        dispatch(updateDialogStates({ executeBookmarkSpinner: true }));

        const filterValuesOptByUser = getSelectedFilterValuesFromOpMetrics(filterOptions);
        const filtersInResponseData = getFiltersInResponseRequestBody(
          ppmReportRequestData.searchCriteria.filter
        );
        // to include user default filters only for LISR
        if (filterOptions && filterOptions.length > 0) {
          filtersInResponseData.push(...filterValuesOptByUser);
        }

        const filteredData = [...new Set(filtersInResponseData.map(({ primary }) => primary)
          .filter(Boolean))]
          .map((primary) => filtersInResponseData.find(({ primary: p }) => p === primary));

        const savedSearchRequestBody = {
          savedSearchID: ppmReportRequestData.id,
          ...requestBody,
          filtersInResponse: filteredData
        };

        dispatch(ppmcallPPMReportSavedSearchAPI({
          savedSearchRequestBody,
          requestBody,
          ppmReportRequestData,
          rowsPerPageOption,
          filterOptions,
          state,
          callback
        }));
      }
    );
  }

  function fetchPpmReportsOffsetApi() {
    return createAsyncThunk(
      `${name}/fetchPpmReportsOffsetApi`,
      async ({ updatedRequest }, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));
        request({
          api: 'ppmReportSearch',
          method: 'post',
          data: updatedRequest
        }, dispatch, getState).then((response: Object) => {
          dispatch(actions.ppmReportOffset(getflattenResponse(response.data)));
          dispatch(toggleSpinner(false));
          dispatch(actions.resetPPMReportSearchPanelId());
        }).catch((error: Object) => {
          console.log('Error: ', error);
          dispatch(toggleSpinner(false));
        });
      }
    );
  }

  function fetchPPMreportsResultsOffset() {
    return createAsyncThunk(
      `${name}/fetchPPMreportsResultsOffset`,
      async (
        {
          ppmFetchPpmReportsOffsetApi
        }, { getState, dispatch }
      ) => {
        const state = getState();
        if (state.ppmReport.ppmReportRequestBody
          && state.ppmReport.ppmReportResultData
        ) {
          let updatedRequest = state.ppmReport.ppmReportRequestBody;
          updatedRequest = setFieldItemVas(updatedRequest);
          updatedRequest = setFieldAsiaSize(updatedRequest);
          updatedRequest = setFilterAsiaSize(updatedRequest);
          delete updatedRequest.offset;
          updatedRequest.offset = '0';
          delete updatedRequest.filtersInResponse;

          dispatch(ppmFetchPpmReportsOffsetApi({ updatedRequest }));
        }
      }
    );
  }

  function ppmReportDataOffset() {
    return createAsyncThunk(
      `${name}/ppmReportDataOffset`,
      async (
        {
          ppmFetchPPMreportsResultsOffset,
          ppmFetchPpmReportsOffsetApi
        }, { dispatch }
      ) => {
        dispatch(ppmFetchPPMreportsResultsOffset({
          ppmFetchPpmReportsOffsetApi
        }));
      }
    );
  }

  function fetchPpmReportNextApi() {
    return createAsyncThunk(
      `${name}/fetchPpmReportNextApi`,
      async ({
        requestBodyWithoutFilters,
        updatedRequest,
        currentResultSet
      }, { getState, dispatch }) => {
        dispatch(togglePaginationSpinner({ isFetchingNextData: true }));
        request({
          api: 'ppmReportSearch',
          method: 'post',
          data: requestBodyWithoutFilters
        }, dispatch, getState)
          .then((response) => {
            dispatch(actions.updatePPMReportRequestBodyOnSameBookmark(updatedRequest));
            dispatch(togglePaginationSpinner({ isFetchingNextData: false }));
            dispatch(toggleSpinner(false));
            dispatch(actions.updatePPMReportNextResult({
              response, currentResultSet
            }));
          })
          .catch((error) => {
            dispatch(togglePaginationSpinner({ isFetchingNextData: false }));
            console.log('NEXT Error ', error);
          });
      }
    );
  }

  function fetchPpmReportNextResultSet() {
    return createAsyncThunk(
      `${name}/fetchPpmReportNextResultSet`,
      async (
        { ppmFetchPpmReportNextApi },
        { getState, dispatch }
      ) => {
        const state = getState();
        if (state.ppmReport.ppmReportRequestBody
          && state.ppmReport.ppmReportResultData
          && state.ppmReport.ppmReportResultData.pages.next
        ) {
          const currentResultSet = state.ppmReport.ppmReportResultData;
          let updatedRequest = state.ppmReport.ppmReportRequestBody;
          const searchResultNextOffset = state.ppmReport
            .ppmReportResultData.pages.next;
          // delete updatedRequest.offset;
          const resNext = searchResultNextOffset.indexOf(',');
          updatedRequest = {
            ...updatedRequest,
            offset: searchResultNextOffset.slice(7, resNext)
          };
          let requestBodyWithoutFilters = { ...updatedRequest };
          requestBodyWithoutFilters = setFieldItemVas(requestBodyWithoutFilters);
          requestBodyWithoutFilters = setFieldAsiaSize(requestBodyWithoutFilters);
          requestBodyWithoutFilters = setFilterAsiaSize(requestBodyWithoutFilters);
          delete requestBodyWithoutFilters.filtersInResponse;

          dispatch(ppmFetchPpmReportNextApi({
            requestBodyWithoutFilters,
            updatedRequest,
            currentResultSet
          }));
        }
      }
    );
  }

  function reportsDataNextSet() {
    return createAsyncThunk(
      `${name}/reportsDataNextSet`,
      async (
        { ppmFetchPpmReportNextResultSet, ppmFetchPpmReportNextApi },
        { dispatch }
      ) => {
        dispatch(ppmFetchPpmReportNextResultSet({ ppmFetchPpmReportNextApi }));
      }
    );
  }

  function fetchPpmReportPrevApi() {
    return createAsyncThunk(
      `${name}/fetchPpmReportPrevApi`,
      async ({
        requestBody,
        updatedRequest,
        currentResultSet
      }, { getState, dispatch }) => {
        dispatch(togglePaginationSpinner({ isFetchingPrevData: true }));
        request({
          api: 'ppmReportSearch',
          method: 'post',
          data: requestBody
        }, dispatch, getState)
          .then((response) => {
            dispatch(actions.updatePPMReportRequestBodyOnSameBookmark(updatedRequest));
            dispatch(togglePaginationSpinner({ isFetchingPrevData: false }));
            dispatch(toggleSpinner(false));
            dispatch(actions.updatePPMReportPrevResult({
              response, currentResultSet
            }));
          })
          .catch((error) => {
            dispatch(togglePaginationSpinner({ isFetchingPrevData: false }));
            console.log('NEXT Error ', error);
          });
      }
    );
  }

  function fetchPpmReportPrevResultSet() {
    return createAsyncThunk(
      `${name}/fetchPpmReportPrevResultSet`,
      async (
        {
          ppmFetchPpmReportPrevApi
        }, { getState, dispatch }
      ) => {
        const state = getState();
        if (state.ppmReport && state.ppmReport.ppmReportResultData
          && state.ppmReport.ppmReportResultData.pages.prev
        ) {
          const currentResultSet = state.ppmReport.ppmReportResultData;
          let updatedRequest = state.ppmReport.ppmReportRequestBody;
          const searchResultPrevOffset = state.ppmReport.ppmReportResultData.pages.prev;
          // delete updatedRequest.offset;
          const res = searchResultPrevOffset.indexOf(',');
          updatedRequest = {
            ...updatedRequest,
            offset: searchResultPrevOffset.slice(7, res)
          };
          let requestBody = { ...updatedRequest };
          requestBody = setFieldItemVas(requestBody);
          requestBody = setFieldAsiaSize(requestBody);
          requestBody = setFilterAsiaSize(requestBody);
          delete requestBody.filtersInResponse;

          dispatch(ppmFetchPpmReportPrevApi({
            requestBody,
            updatedRequest,
            currentResultSet
          }));
        }
      }
    );
  }

  function reportsDataPrevSet() {
    return createAsyncThunk(
      `${name}/reportsDataPrevSet`,
      async (
        {
          ppmFetchPpmReportPrevResultSet,
          ppmFetchPpmReportPrevApi
        }, { dispatch }
      ) => {
        dispatch(ppmFetchPpmReportPrevResultSet({ ppmFetchPpmReportPrevApi }));
      }
    );
  }

  // ppmReportResults -- end

  function applyFilters() {
    return createAsyncThunk(
      `${name}/applyFilters`,
      async ({
        payload, callback,
        shouldRequestFilterValues = false
      }, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));

        const state = getState();
        const selectedFilters = {
          ...state.ppmReport.selectedFilters,
          [payload.filter]: payload.values
        };
        let requestBody = {
          ...state.ppmReport.ppmReportRequestBody,
          filter: mapFiltersToRequest(selectedFilters),
          offset: '0'
        };
        const selectedFilterKeys = Object.keys(selectedFilters)
          .filter((filter) => selectedFilters[filter]);
        if (shouldRequestFilterValues && selectedFilterKeys.length > 0) {
          getFiltersForRequest(requestBody, selectedFilterKeys);
        } else {
          delete requestBody.filtersInResponse;
        }
        requestBody = setFieldItemVas(requestBody);
        requestBody = setFieldAsiaSize(requestBody);
        requestBody = setFilterAsiaSize(requestBody);
        request({
          api: 'ppmReportSearch',
          method: 'post',
          data: requestBody,
          cancellable: true
        }, dispatch, getState).then((response: Object) => {
          requestBody = removeFieldAsiaSize(requestBody);
          dispatch(actions.updatePPMReportRequestBody(requestBody));
          dispatch(toggleSpinner(false));
          const flattenedResponse = getflattenResponse(response.data);
          dispatch(actions.updatePPMReportResults(flattenedResponse));
          dispatch(actions.updatePPMPage(0));
          dispatch(actions.setPpmReportFilterId());
          dispatch(actions.bulkUpdateReportFilterValues(payload));
          if (shouldRequestFilterValues && selectedFilterKeys.length > 0) {
            dispatch(actions.updateReportFiltersInResposne(requestBody.filtersInResponse));
          }
          if (response.data.filters) {
            dispatch(actions.updateReportFilterDropDownValues(
              getFilterValuesFromResponse(response.data.filters)
            ));
          }
          if (callback) {
            callback(response);
          }
        }).catch((error: Object) => {
          console.log('Error: ', error);
          dispatch(toggleSpinner(false));
          if (callback) {
            callback(null, error);
          }
        });
      }
    );
  }

  function fetchUnfilteredPOResults() {
    // TODO: callback function need add in props
    return createAsyncThunk(
      `${name}/fetchUnfilteredPOResults`,
      async (_, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));
        const state = getState();
        let requestBody = {
          ...state.ppmReport.ppmReportRequestBody,
          filter: [],
          offset: '0'
        };
        requestBody = setFieldItemVas(requestBody);
        requestBody = setFieldAsiaSize(requestBody);
        requestBody = setFilterAsiaSize(requestBody);

        request({
          api: 'ppmReportSearch',
          method: 'post',
          data: requestBody,
          cancellable: true
        }, dispatch, getState).then((response: Object) => {
          dispatch(toggleSpinner(false));
          dispatch(actions.clearFilters());
          dispatch(actions.resetPPMReportSearchPanelId());
          dispatch(actions.updateReportFiltersRequest([]));
          dispatch(actions.updatePPMReportResults(getflattenResponse(response.data)));
          // if (callback) {
          //   callback(response);
          // }
        }).catch((error: Object) => {
          console.log('Error: ', error);
          dispatch(toggleSpinner(false));
          // if (callback) {
          //   callback(null, error);
          // }
        });
      }
    );
  }

  function aynscCallToDispatches() {
    return createAsyncThunk(
      `${name}/aynscCallToDispatches`,
      async (
        {
          response,
          filterDropdownValues,
          filtersInResponse,
          hasAppliedfiltersChanged,
          filtersToBeApplied,
          requestNewFiltersCallback

        },

        {
          dispatch
        }
      ) => {
        if (response.data.filters) {
          dispatch(actions.updateReportFilterDropDownValues({
            ...filterDropdownValues,
            ...getFilterValuesFromResponse(response.data.filters)
          }));
          dispatch(actions.updateReportFiltersInResposne(filtersInResponse));
        }
        if (hasAppliedfiltersChanged) {
          dispatch(actions.updateReportFiltersRequest(filtersToBeApplied));
          dispatch(actions.updatePPMReportResults(getflattenResponse(response.data)));
          dispatch(actions.updatePPMPage(0));
        }
        if (requestNewFiltersCallback) {
          requestNewFiltersCallback(response);
        }
      }
    );
  }

  function requestForNewFilters() {
    return createAsyncThunk(
      `${name}/requestForNewFilters`,
      async ({
        filtersSelected, enableCheckboxForDefault,
        requestNewFiltersCallback,
        TaynscCallToDispatches
      }, { getState, dispatch }) => {
        const state = getState();
        let requestBody = { ...state.ppmReport.ppmReportRequestBody };
        requestBody = setFieldItemVas(requestBody);
        requestBody = setFieldAsiaSize(requestBody);
        requestBody = setFilterAsiaSize(requestBody);
        const {
          selectedFilters,
          filterDropdownValues
        } = state.ppmReport;
        const modifiedSelectedFilters = { ...selectedFilters };
        Object.keys(modifiedSelectedFilters).forEach((filter: string) => {
          getModifiedSelectedFilters(filtersSelected, filter, modifiedSelectedFilters);
        });
        const filtersToBeRequested = [];
        const filtersInResponse = [];
        let filtersToBeApplied = null;

        const selectedFilterKeys = Object.keys(modifiedSelectedFilters)
          .filter((filter) => modifiedSelectedFilters[filter]);
        const appliedFiltersKeys = selectedFilterKeys.filter((filter) => (
          modifiedSelectedFilters[filter] && modifiedSelectedFilters[filter].length > 0
        ));
        const hasAppliedfiltersChanged = (!(requestBody.filter || []).every((filter) => (
          appliedFiltersKeys.includes(filter.fieldName)
        )));

        if (hasAppliedfiltersChanged) {
          filtersToBeApplied = mapFiltersToRequest(modifiedSelectedFilters);
          requestBody.filter = [...filtersToBeApplied];
        }

        selectedFilterKeys.forEach((filter) => {
          getSelectedFilterKeys(
            filter, modifiedSelectedFilters, filterDropdownValues,
            filtersToBeRequested, filtersInResponse
          );
        });
        if (filtersToBeRequested.length === 0) {
          delete requestBody.filtersInResponse;
        } else {
          requestBody.filtersInResponse = filtersToBeRequested;
        }
        requestBody.offset = '0';
        const selectedFilterData = {
          selectedFilterListForPPMReport: filtersSelected
        };
        dispatch(toggleSpinner(true));
        if (enableCheckboxForDefault) {
          dispatch(settingsActions.userProfileSettings({
            requestBody: {
              selectedFilterListForPPMReport: selectedFilterData.selectedFilterListForPPMReport
            }
          }));
        }
        request({
          api: 'ppmReportSearch',
          method: 'post',
          data: requestBody,
          cancellable: true
        }, dispatch, getState).then((response: Object) => {
          dispatch(toggleSpinner(false));
          dispatch(actions.updatePPMPage(0));
          dispatch(actions.setPpmReportFilterId());
          dispatch(actions.resetPPMReportSearchPanelId());
          dispatch(actions.updatePPMReportOffsetInRequest('0'));
          dispatch(actions.updatePPMReportResults(getflattenResponse(response.data)));
          dispatch(actions.bulkUpdateSelectedFilters(filtersSelected));
          dispatch(TaynscCallToDispatches({
            response,
            filterDropdownValues,
            filtersInResponse,
            hasAppliedfiltersChanged,
            filtersToBeApplied,
            requestNewFiltersCallback
          }));
        }).catch((error: Object) => {
          console.log('Error: ', error);
          dispatch(toggleSpinner(false));
          asyncCallToNewFiltersCallback(requestNewFiltersCallback, error);
        });
      }
    );
  }

  function getGacReasonCode() {
    return createAsyncThunk(
      `${name}/getGacReasonCode`,
      async ({
        callback
      }, { dispatch }) => {
        const payload = {};
        dispatch(toggleSpinner(true));
        request({
          api: 'getGACReasonCode',
          method: 'get'
        }, dispatch).then((response) => {
          dispatch(toggleSpinner(false));
          payload.gacReasonCode = response.data;
          if (callback) {
            callback(response);
          }
        }).catch((error) => {
          dispatch(toggleSpinner(false));
          if (callback) {
            callback(null, error);
          }
        });
      }
    );
  }

  return {
    callPPMReportSearchAPI: callPPMReportSearchAPI(),
    fetchPOReports: fetchPOReports(),
    exportPOReport: exportPOReport(),
    callPPMReportSavedSearchAPI: callPPMReportSavedSearchAPI(),
    fetchSavedPPMReportResults: fetchSavedPPMReportResults(),
    fetchPpmReportsOffsetApi: fetchPpmReportsOffsetApi(),
    fetchPPMreportsResultsOffset: fetchPPMreportsResultsOffset(),
    ppmReportDataOffset: ppmReportDataOffset(),
    fetchPpmReportNextApi: fetchPpmReportNextApi(),
    fetchPpmReportNextResultSet: fetchPpmReportNextResultSet(),
    reportsDataNextSet: reportsDataNextSet(),
    fetchPpmReportPrevApi: fetchPpmReportPrevApi(),
    fetchPpmReportPrevResultSet: fetchPpmReportPrevResultSet(),
    reportsDataPrevSet: reportsDataPrevSet(),
    applyFilters: applyFilters(),
    fetchUnfilteredPOResults: fetchUnfilteredPOResults(),
    aynscCallToDispatches: aynscCallToDispatches(),
    requestForNewFilters: requestForNewFilters(),
    getGacReasonCode: getGacReasonCode()
  };
}

export const extraActions = createExtraActions();

export const ppmReportRequestActions = { ...actions, ...extraActions };
export const ppmReportRequestReducer = slice.reducer;
