/**
 * This file is used for storing the constants data.
 * @exports dataTableHeader
 * @author {Cognizant Technology Solution}
 */
import fieldMapping from './fieldMappings/poSearchFieldMapping';
import ppmReportsHeader, { poReportsHeader } from './ppmReportsHeader';
import customReportHeader from './poChangeReportHeader';
import poSearchFieldProperties from './fieldProperties/poSearchFieldProperties';
import sireSummaryTableFieldProperties from './fieldProperties/sireSummaryTableFieldProperties';
import sireProfileTableFieldProperties from './fieldProperties/sireProfileTableFieldProperties';
import myRequestTableFieldProperties from './fieldProperties/myRequestTableFieldProperties';
import gacUpdateTableFieldProperties from './fieldProperties/gacUpdateTableFieldProperties';
import gacEventTableFieldProperties from './fieldProperties/gacEventTableFieldProperties';
import gacEventErrorTableFieldProperties from './fieldProperties/gacEventErrorTableFieldProperties';
import rejectionEventErrorTableFieldProperties from './fieldProperties/rejectionEventErrorTableFieldProperties';
import wipTableFieldProperties from './fieldProperties/wipTableFieldProperties';
import poRejectionEventTableFieldProperties from './fieldProperties/poRejectionEventTableFieldProperties';
import poEventErrorTableFieldProperties from './fieldProperties/poEventErrorTableFieldProperties';
import pmoDecTableFieldProperties from './fieldProperties/pmoDecFieldProperties';
import fileDownloadPOPDFTableFieldProperties from './fieldProperties/fileDownloadPOPDFTableFieldProperties';
import fileDownloadSireTableFieldProperties from './fieldProperties/fileDownloadSireTableFieldProperties';
import fileDownloadReportTableFieldProperties from './fieldProperties/fileDownloadReportTableFieldProperties';
import fileDownloadPOSearchTableFieldProperties from './fieldProperties/fileDownloadPOSearchTableFieldProperties';
import geoGlobalFieldProperties from './fieldProperties/geoGlobalFieldProperties';
import conversationCategoryCodesFieldProperties from './fieldProperties/conversationCategoryCodesFieldProperties';
import gacReasonCodeFieldProperties from './fieldProperties/gacReasonCodeFieldProperties';
import gacReasonCodeFieldMapping from './fieldMappings/gacReasonCodeFieldMapping';
import gacReasonUsecasesFieldMapping from './fieldMappings/gacReasonUsecasesFieldMapping';
import gacReasonUsecasesFieldProperties from './fieldProperties/gacReasonUsecasesFieldProperties';
import airFreightOverrideFieldProperties from './fieldProperties/airFreightOverrideFieldProperties';
import {
  pricingNonProd, showForNONPROD, showForFWTooling
} from '../helpers/envServer';
import geoEmailGroupFieldProperties from './fieldProperties/geoEmailGroupFieldProperties';
import featureFlagFieldProperties from './fieldProperties/featureFlagFieldProperties';

const dataTableHeader = [
  poSearchFieldProperties[fieldMapping.PURCHASE_ORDER_NUMBER],
  poSearchFieldProperties[fieldMapping.PO_LINE_ITEM_NUMBER],
  poSearchFieldProperties[fieldMapping.SCHEDULE_LINE_ITEM_NUMBER],
  poSearchFieldProperties[fieldMapping.SIZE_DESC],
  poSearchFieldProperties[fieldMapping.TR_CO_PURCHASE_ORDER_NUMBER],
  poSearchFieldProperties[fieldMapping.VENDOR_CODE],
  poSearchFieldProperties[fieldMapping.PMO_DEC_CODE],
  poSearchFieldProperties[fieldMapping.PURCHASING_DOCUMENT_TYPE_CODE],
  poSearchFieldProperties[fieldMapping.DOCUMENT_DATE],
  poSearchFieldProperties[fieldMapping.CHANGE_DATE],
  poSearchFieldProperties[fieldMapping.PLANNING_PRIORITY_NUMBER_EDP_CODE],
  poSearchFieldProperties[fieldMapping.MRGAC],
  poSearchFieldProperties[fieldMapping.OGAC],
  poSearchFieldProperties[fieldMapping.GAC],
  poSearchFieldProperties[fieldMapping.GAC_REASON_CODE],
  poSearchFieldProperties[fieldMapping.GAC_USECASE_CODE],
  poSearchFieldProperties[fieldMapping.PRODUCT_CODE],
  poSearchFieldProperties[fieldMapping.SIZE_QUANTITY],
  poSearchFieldProperties[fieldMapping.MODE_OF_TRANSPORTATION],
  poSearchFieldProperties[fieldMapping.PLANT_CODE],
  poSearchFieldProperties[fieldMapping.PURCHASE_ORDER_FOB_SIZE_AMOUNT],
  poSearchFieldProperties[fieldMapping.SHIPPING_TYPE]
].filter((el) => el !== false);

export default dataTableHeader;
export const dataTableHeaderInactv = [
  { primary: 'id' },
  { primary: 'isLine' }
  // { primary: fieldMapping.VAS_SIZE },
  // { primary: fieldMapping.FX_ADJ_REQUIRED_INDICATOR },
  // { primary: fieldMapping.IS_TRCO_RELEVANT }
];

export const dataTableCustomView = pricingNonProd ? [
  poSearchFieldProperties[fieldMapping.DIVERT_FLAG],
  poSearchFieldProperties[fieldMapping.DIVERTED_FROM],
  poSearchFieldProperties[fieldMapping.DIVERTED_TO],
  poSearchFieldProperties[fieldMapping.INVENTORY_SEGMENT_CODE],
  poSearchFieldProperties[fieldMapping.TR_CO_FOB],
  poSearchFieldProperties[fieldMapping.FX_ADJ_REQUIRED_INDICATOR],
  poSearchFieldProperties[fieldMapping.FX_ADJ_DATE],
  poSearchFieldProperties[fieldMapping.FOREIGN_EXCHANGE],
  // poSearchFieldProperties[fieldMapping.TR_CO_FOREIGN_EXCHANGE],
  poSearchFieldProperties[fieldMapping.NET_INCL_DISCOUNTS_AMOUNT],
  poSearchFieldProperties[fieldMapping.TR_CO_INCL_DISCOUNTS],
  poSearchFieldProperties[fieldMapping.NET_INCL_SURCH_AMOUNT],
  // poSearchFieldProperties[fieldMapping.TR_CO_INCL_SURC],
  poSearchFieldProperties[fieldMapping.TRCO_RELEVANT_INDICATOR],
  poSearchFieldProperties[fieldMapping.SIZE_SORT_SEQUENCE_NUMBER],
  poSearchFieldProperties[fieldMapping.UPC],
  poSearchFieldProperties[fieldMapping.PRODUCT_FOB_AMOUNT],
  (showForFWTooling) && poSearchFieldProperties[fieldMapping.POSEARCH_PRODUCT_TOOLING_COST],
  poSearchFieldProperties[fieldMapping.FOB_MISMATCH_INDICATOR],
  (showForFWTooling) && poSearchFieldProperties[fieldMapping.POSEARCH_TOOLING_MISMATCH_INDICATOR],
  poSearchFieldProperties[fieldMapping.SIZE_MISMATCH_INDICATOR],
  poSearchFieldProperties[fieldMapping.CREATED_BY],
  poSearchFieldProperties[fieldMapping.CREATE_DATE],
  poSearchFieldProperties[fieldMapping.INTERNATIONAL_COMMERCIAL_TERMS1],
  poSearchFieldProperties[fieldMapping.MCO],
  poSearchFieldProperties[fieldMapping.DESTINATION_COUNTRY_CODE],
  poSearchFieldProperties[fieldMapping.GEOGRAPHY_CODE],
  poSearchFieldProperties[fieldMapping.COMPANY_CODE],
  poSearchFieldProperties[fieldMapping.TR_CO_CODE],
  poSearchFieldProperties[fieldMapping.PURCHASE_GROUP_CODE],
  poSearchFieldProperties[fieldMapping.PURCHASE_ORG_CODE],
  poSearchFieldProperties[fieldMapping.TTMI_CODE],
  poSearchFieldProperties[fieldMapping.PO_LINE_ITEM_CANCEL_DATE],
  poSearchFieldProperties[fieldMapping.STATISTICAL_DELIVERY_DATE],
  poSearchFieldProperties[fieldMapping.DIRECT_SHIP_SALES_ORDER_NUMBER],
  poSearchFieldProperties[fieldMapping.DIRECT_SHIP_SALES_ORDER_ITEM],
  poSearchFieldProperties[fieldMapping.DIVISION],
  poSearchFieldProperties[fieldMapping.PO_LINE_ITEM_STATUS],
  poSearchFieldProperties[fieldMapping.TOTAL_ITEM_QUANTITY],
  poSearchFieldProperties[fieldMapping.UNIT_OF_MEASURE],
  poSearchFieldProperties[fieldMapping.ORDER_REASON_CODE],
  poSearchFieldProperties[fieldMapping.SEASON],
  poSearchFieldProperties[fieldMapping.YEAR],
  poSearchFieldProperties[fieldMapping.TR_CO_PLANT_CODE],
  poSearchFieldProperties[fieldMapping.CURRENT_EVENT],
  poSearchFieldProperties[fieldMapping.CURRENT_EVENT_DATE],
  poSearchFieldProperties[fieldMapping.NEXT_EVENT],
  poSearchFieldProperties[fieldMapping.NEXT_EVENT_DATE],
  poSearchFieldProperties[fieldMapping.OVER_DUE_INDICATOR],
  poSearchFieldProperties[fieldMapping.ISSUE_PO_EXPECTED_DATE],
  poSearchFieldProperties[fieldMapping.ISSUE_PO_ACTUAL_DATE],
  poSearchFieldProperties[fieldMapping.ACCEPT_PO_EXPECTED_DATE],
  poSearchFieldProperties[fieldMapping.PO_ACCEPTANCE_DATE],
  poSearchFieldProperties[fieldMapping.MATERIAL_ORDER_EXPECTED_DATE],
  poSearchFieldProperties[fieldMapping.MATERIAL_ORDER_ACTUAL_DATE],
  poSearchFieldProperties[fieldMapping.MATERIAL_TRIM_RECEIPT_EXPECTED_DATE],
  poSearchFieldProperties[fieldMapping.MATERIAL_TRIM_RECEIPT_ACTUAL_DATE],
  poSearchFieldProperties[fieldMapping.QRS_EXPECTED_DATE],
  poSearchFieldProperties[fieldMapping.QRS_ACTUAL_DATE],
  poSearchFieldProperties[fieldMapping.PRODUCTION_START_EXPECTED_DATE],
  poSearchFieldProperties[fieldMapping.PRODUCTION_START_ACTUAL_DATE],
  poSearchFieldProperties[fieldMapping.PRODUCTION_END_EXPECTED_DATE],
  poSearchFieldProperties[fieldMapping.PRODUCTION_END_ACTUAL_DATE],
  poSearchFieldProperties[fieldMapping.EX_FACTORY_EXPECTED_DATE],
  poSearchFieldProperties[fieldMapping.EX_FACTORY_ACTUAL_DATE],
  poSearchFieldProperties[fieldMapping.FACTORY_DELIVERY_EXPECTED_DATE],
  poSearchFieldProperties[fieldMapping.FACTORY_DELIVERY_ACTUAL_DATE],
  poSearchFieldProperties[fieldMapping.REJECTION_CODE],
  poSearchFieldProperties[fieldMapping.PRODUCT_NAME],
  poSearchFieldProperties[fieldMapping.ADVANCED_SHIPMENT_NOTICE_ASN_DATE],
  poSearchFieldProperties[fieldMapping.ASN_QUANTITY],
  poSearchFieldProperties[fieldMapping.ORIGIN_RECEIPT_DATE],
  poSearchFieldProperties[fieldMapping.ORIGIN_RECEIPT_QUANTITY],
  poSearchFieldProperties[fieldMapping.FACTORY_PROOF_OF_DELIVERY_DATE],
  poSearchFieldProperties[fieldMapping.EX_FACTORY_QUANTITY],
  poSearchFieldProperties[fieldMapping.INITIAL_GAC],
  poSearchFieldProperties[fieldMapping.INITIAL_GAC_REASON_CODE],
  poSearchFieldProperties[fieldMapping.PREVIOUS_GAC],
  poSearchFieldProperties[fieldMapping.PREVIOUS_GAC_REASON_CODE],
  poSearchFieldProperties[fieldMapping.DELIVERY_COMPLETE_INDICATOR],
  poSearchFieldProperties[fieldMapping.LAUNCH_INDICATOR],
  poSearchFieldProperties[fieldMapping.LAUNCH_DATE],
  poSearchFieldProperties[fieldMapping.CATEGORY_CODE],
  poSearchFieldProperties[fieldMapping.SUB_CATEGORY_CODE],
  poSearchFieldProperties[fieldMapping.BLANK_COLOR],
  poSearchFieldProperties[fieldMapping.BLANK_PRODUCT_CODE],
  poSearchFieldProperties[fieldMapping.BLANK_STYLE_DISP_NUMBER],
  poSearchFieldProperties[fieldMapping.STYLE_NUMBER],
  poSearchFieldProperties[fieldMapping.COLOR_DESC],
  poSearchFieldProperties[fieldMapping.SIZE_DIMENSION],
  poSearchFieldProperties[fieldMapping.GENDER_AGE_CODE],
  poSearchFieldProperties[fieldMapping.GLOBAL_CATEGORY_CORE_FOCUS_CODE],
  poSearchFieldProperties[fieldMapping.GLOBAL_CATEGORY_SUMMARY_CODE],
  poSearchFieldProperties[fieldMapping.MARKETING_ID],
  poSearchFieldProperties[fieldMapping.PRODUCT_REFILL_CLASS_CODE],
  poSearchFieldProperties[fieldMapping.SILHOUETTE_CODE],
  poSearchFieldProperties[fieldMapping.SILO_CODE],
  poSearchFieldProperties[fieldMapping.SPORT_ACTIVITY_CODE],
  poSearchFieldProperties[fieldMapping.TEAM_ID],
  poSearchFieldProperties[fieldMapping.TEAM_NAME],
  poSearchFieldProperties[fieldMapping.TEAM_SILHOUETTE_ID],
  poSearchFieldProperties[fieldMapping.LEAGUE_ID_CODE],
  poSearchFieldProperties[fieldMapping.ATHLETE_NAME],
  poSearchFieldProperties[fieldMapping.ATHLETE_ID],
  poSearchFieldProperties[fieldMapping.MIDSOLE_CODE],
  poSearchFieldProperties[fieldMapping.OUTSOLE_CODE],
  poSearchFieldProperties[fieldMapping.PROMO_ONLY_INDICATOR],
  poSearchFieldProperties[fieldMapping.SALES_ORDER_NUMBER],
  poSearchFieldProperties[fieldMapping.SALES_ORDER_ITEM_NUMBER],
  poSearchFieldProperties[fieldMapping.CUSTOMER_PO],
  poSearchFieldProperties[fieldMapping.CUSTOMER_REQUESTED_DATE],
  poSearchFieldProperties[fieldMapping.ADDRESS_OVERRIDE_INDICATOR],
  poSearchFieldProperties[fieldMapping.SHIP_TO_CUSTOMER_NUMBER],
  poSearchFieldProperties[fieldMapping.SHIP_TO_CUSTOMER_NAME],
  poSearchFieldProperties[fieldMapping.DISTRIBUTION_CHANNEL_CODE],
  poSearchFieldProperties[fieldMapping.SALES_ORGANIZATION_CODE],
  poSearchFieldProperties[fieldMapping.GLOBAL_PLANNING_PRODUCT_CLASS_CODE],
  poSearchFieldProperties[fieldMapping.GLOBAL_PLANNING_PRODUCT_GROUP],
  poSearchFieldProperties[fieldMapping.ITEM_TEXT],
  poSearchFieldProperties[fieldMapping.VAS_ITEM],
  poSearchFieldProperties[fieldMapping.VAS_SIZE],
  poSearchFieldProperties[fieldMapping.FSP],
  poSearchFieldProperties[fieldMapping.POSEARCH_ASIAN_CC]
].filter((el) => el !== false) : [
  poSearchFieldProperties[fieldMapping.INVENTORY_SEGMENT_CODE],
  poSearchFieldProperties[fieldMapping.TR_CO_FOB],
  poSearchFieldProperties[fieldMapping.FX_ADJ_REQUIRED_INDICATOR],
  poSearchFieldProperties[fieldMapping.FX_ADJ_DATE],
  poSearchFieldProperties[fieldMapping.FOREIGN_EXCHANGE],
  poSearchFieldProperties[fieldMapping.TR_CO_FOREIGN_EXCHANGE],
  poSearchFieldProperties[fieldMapping.NET_INCL_DISCOUNTS_AMOUNT],
  poSearchFieldProperties[fieldMapping.TR_CO_INCL_DISCOUNTS],
  poSearchFieldProperties[fieldMapping.NET_INCL_SURCH_AMOUNT],
  poSearchFieldProperties[fieldMapping.TR_CO_INCL_SURC],
  poSearchFieldProperties[fieldMapping.TRCO_RELEVANT_INDICATOR],
  poSearchFieldProperties[fieldMapping.SIZE_SORT_SEQUENCE_NUMBER],
  poSearchFieldProperties[fieldMapping.UPC],
  poSearchFieldProperties[fieldMapping.PRODUCT_FOB_AMOUNT],
  poSearchFieldProperties[fieldMapping.FOB_MISMATCH_INDICATOR],
  poSearchFieldProperties[fieldMapping.SIZE_MISMATCH_INDICATOR],
  poSearchFieldProperties[fieldMapping.CREATED_BY],
  poSearchFieldProperties[fieldMapping.CREATE_DATE],
  poSearchFieldProperties[fieldMapping.INTERNATIONAL_COMMERCIAL_TERMS1],
  poSearchFieldProperties[fieldMapping.MCO],
  poSearchFieldProperties[fieldMapping.DESTINATION_COUNTRY_CODE],
  poSearchFieldProperties[fieldMapping.GEOGRAPHY_CODE],
  poSearchFieldProperties[fieldMapping.COMPANY_CODE],
  poSearchFieldProperties[fieldMapping.TR_CO_CODE],
  poSearchFieldProperties[fieldMapping.PURCHASE_GROUP_CODE],
  poSearchFieldProperties[fieldMapping.PURCHASE_ORG_CODE],
  poSearchFieldProperties[fieldMapping.TTMI_CODE],
  poSearchFieldProperties[fieldMapping.PO_LINE_ITEM_CANCEL_DATE],
  poSearchFieldProperties[fieldMapping.STATISTICAL_DELIVERY_DATE],
  poSearchFieldProperties[fieldMapping.DIRECT_SHIP_SALES_ORDER_NUMBER],
  poSearchFieldProperties[fieldMapping.DIRECT_SHIP_SALES_ORDER_ITEM],
  poSearchFieldProperties[fieldMapping.DIVISION],
  poSearchFieldProperties[fieldMapping.PO_LINE_ITEM_STATUS],
  poSearchFieldProperties[fieldMapping.TOTAL_ITEM_QUANTITY],
  poSearchFieldProperties[fieldMapping.UNIT_OF_MEASURE],
  poSearchFieldProperties[fieldMapping.ORDER_REASON_CODE],
  poSearchFieldProperties[fieldMapping.SEASON],
  poSearchFieldProperties[fieldMapping.YEAR],
  poSearchFieldProperties[fieldMapping.TR_CO_PLANT_CODE],
  poSearchFieldProperties[fieldMapping.CURRENT_EVENT],
  poSearchFieldProperties[fieldMapping.CURRENT_EVENT_DATE],
  poSearchFieldProperties[fieldMapping.NEXT_EVENT],
  poSearchFieldProperties[fieldMapping.NEXT_EVENT_DATE],
  poSearchFieldProperties[fieldMapping.OVER_DUE_INDICATOR],
  poSearchFieldProperties[fieldMapping.ISSUE_PO_EXPECTED_DATE],
  poSearchFieldProperties[fieldMapping.ISSUE_PO_ACTUAL_DATE],
  poSearchFieldProperties[fieldMapping.ACCEPT_PO_EXPECTED_DATE],
  poSearchFieldProperties[fieldMapping.PO_ACCEPTANCE_DATE],
  poSearchFieldProperties[fieldMapping.MATERIAL_ORDER_EXPECTED_DATE],
  poSearchFieldProperties[fieldMapping.MATERIAL_ORDER_ACTUAL_DATE],
  poSearchFieldProperties[fieldMapping.MATERIAL_TRIM_RECEIPT_EXPECTED_DATE],
  poSearchFieldProperties[fieldMapping.MATERIAL_TRIM_RECEIPT_ACTUAL_DATE],
  poSearchFieldProperties[fieldMapping.QRS_EXPECTED_DATE],
  poSearchFieldProperties[fieldMapping.QRS_ACTUAL_DATE],
  poSearchFieldProperties[fieldMapping.PRODUCTION_START_EXPECTED_DATE],
  poSearchFieldProperties[fieldMapping.PRODUCTION_START_ACTUAL_DATE],
  poSearchFieldProperties[fieldMapping.PRODUCTION_END_EXPECTED_DATE],
  poSearchFieldProperties[fieldMapping.PRODUCTION_END_ACTUAL_DATE],
  poSearchFieldProperties[fieldMapping.EX_FACTORY_EXPECTED_DATE],
  poSearchFieldProperties[fieldMapping.EX_FACTORY_ACTUAL_DATE],
  poSearchFieldProperties[fieldMapping.FACTORY_DELIVERY_EXPECTED_DATE],
  poSearchFieldProperties[fieldMapping.FACTORY_DELIVERY_ACTUAL_DATE],
  poSearchFieldProperties[fieldMapping.REJECTION_CODE],
  poSearchFieldProperties[fieldMapping.PRODUCT_NAME],
  poSearchFieldProperties[fieldMapping.ADVANCED_SHIPMENT_NOTICE_ASN_DATE],
  poSearchFieldProperties[fieldMapping.ASN_QUANTITY],
  poSearchFieldProperties[fieldMapping.ORIGIN_RECEIPT_DATE],
  poSearchFieldProperties[fieldMapping.ORIGIN_RECEIPT_QUANTITY],
  poSearchFieldProperties[fieldMapping.FACTORY_PROOF_OF_DELIVERY_DATE],
  poSearchFieldProperties[fieldMapping.EX_FACTORY_QUANTITY],
  poSearchFieldProperties[fieldMapping.INITIAL_GAC],
  poSearchFieldProperties[fieldMapping.INITIAL_GAC_REASON_CODE],
  poSearchFieldProperties[fieldMapping.PREVIOUS_GAC],
  poSearchFieldProperties[fieldMapping.PREVIOUS_GAC_REASON_CODE],
  poSearchFieldProperties[fieldMapping.DELIVERY_COMPLETE_INDICATOR],
  poSearchFieldProperties[fieldMapping.LAUNCH_INDICATOR],
  poSearchFieldProperties[fieldMapping.LAUNCH_DATE],
  poSearchFieldProperties[fieldMapping.CATEGORY_CODE],
  poSearchFieldProperties[fieldMapping.SUB_CATEGORY_CODE],
  poSearchFieldProperties[fieldMapping.BLANK_COLOR],
  poSearchFieldProperties[fieldMapping.BLANK_PRODUCT_CODE],
  poSearchFieldProperties[fieldMapping.BLANK_STYLE_DISP_NUMBER],
  poSearchFieldProperties[fieldMapping.STYLE_NUMBER],
  poSearchFieldProperties[fieldMapping.COLOR_DESC],
  poSearchFieldProperties[fieldMapping.SIZE_DIMENSION],
  poSearchFieldProperties[fieldMapping.GENDER_AGE_CODE],
  poSearchFieldProperties[fieldMapping.GLOBAL_CATEGORY_CORE_FOCUS_CODE],
  poSearchFieldProperties[fieldMapping.GLOBAL_CATEGORY_SUMMARY_CODE],
  poSearchFieldProperties[fieldMapping.MARKETING_ID],
  poSearchFieldProperties[fieldMapping.PRODUCT_REFILL_CLASS_CODE],
  poSearchFieldProperties[fieldMapping.SILHOUETTE_CODE],
  poSearchFieldProperties[fieldMapping.SILO_CODE],
  poSearchFieldProperties[fieldMapping.SPORT_ACTIVITY_CODE],
  poSearchFieldProperties[fieldMapping.TEAM_ID],
  poSearchFieldProperties[fieldMapping.TEAM_NAME],
  poSearchFieldProperties[fieldMapping.TEAM_SILHOUETTE_ID],
  poSearchFieldProperties[fieldMapping.LEAGUE_ID_CODE],
  poSearchFieldProperties[fieldMapping.ATHLETE_NAME],
  poSearchFieldProperties[fieldMapping.ATHLETE_ID],
  poSearchFieldProperties[fieldMapping.MIDSOLE_CODE],
  poSearchFieldProperties[fieldMapping.OUTSOLE_CODE],
  poSearchFieldProperties[fieldMapping.PROMO_ONLY_INDICATOR],
  poSearchFieldProperties[fieldMapping.SALES_ORDER_NUMBER],
  poSearchFieldProperties[fieldMapping.SALES_ORDER_ITEM_NUMBER],
  poSearchFieldProperties[fieldMapping.CUSTOMER_PO],
  poSearchFieldProperties[fieldMapping.CUSTOMER_REQUESTED_DATE],
  (showForNONPROD) && poSearchFieldProperties[fieldMapping.ADDRESS_OVERRIDE_INDICATOR],
  poSearchFieldProperties[fieldMapping.SHIP_TO_CUSTOMER_NUMBER],
  poSearchFieldProperties[fieldMapping.SHIP_TO_CUSTOMER_NAME],
  poSearchFieldProperties[fieldMapping.DISTRIBUTION_CHANNEL_CODE],
  poSearchFieldProperties[fieldMapping.SALES_ORGANIZATION_CODE],
  poSearchFieldProperties[fieldMapping.GLOBAL_PLANNING_PRODUCT_CLASS_CODE],
  poSearchFieldProperties[fieldMapping.GLOBAL_PLANNING_PRODUCT_GROUP],
  poSearchFieldProperties[fieldMapping.ITEM_TEXT],
  poSearchFieldProperties[fieldMapping.VAS_ITEM],
  poSearchFieldProperties[fieldMapping.VAS_SIZE],
  poSearchFieldProperties[fieldMapping.FSP]
];
export const sireSummaryTableHeader = [
  sireSummaryTableFieldProperties.poNumber,
  sireSummaryTableFieldProperties.itemNumber,
  sireSummaryTableFieldProperties.tradingPoNumber,
  sireSummaryTableFieldProperties.profileID,
  sireSummaryTableFieldProperties.geo,
  sireSummaryTableFieldProperties.plantID,
  sireSummaryTableFieldProperties.shipTo,
  sireSummaryTableFieldProperties.division,
  sireSummaryTableFieldProperties.mode,
  sireSummaryTableFieldProperties.buyGroup,
  sireSummaryTableFieldProperties.ttmi,
  sireSummaryTableFieldProperties.destCountry,
  sireSummaryTableFieldProperties.originCountry
];
export const sireProfileTableHeader = [
  sireProfileTableFieldProperties.profileID,
  sireProfileTableFieldProperties.shipToID,
  sireProfileTableFieldProperties.plantID,
  sireProfileTableFieldProperties.division,
  sireProfileTableFieldProperties.buyGroup,
  sireProfileTableFieldProperties.mode,
  sireProfileTableFieldProperties.ttmi,
  sireProfileTableFieldProperties['originCountry.code'],
  sireProfileTableFieldProperties['destCountry.code'],
  sireProfileTableFieldProperties.geo,
  sireProfileTableFieldProperties.deliveryInstruction
];
export const myRequestTableHeader = [
  myRequestTableFieldProperties[fieldMapping.CHANGE_ID],
  myRequestTableFieldProperties[fieldMapping.PO_NUMBER],
  myRequestTableFieldProperties[fieldMapping.PO_LINE_ITEM_NUMBER_REQUEST],
  myRequestTableFieldProperties[fieldMapping.SCHEDULE_LINE_ITEM_NUMBER_REQUEST],
  myRequestTableFieldProperties[fieldMapping.SIZE_DESC_REQUEST],
  myRequestTableFieldProperties[fieldMapping.TRADING_CO_PO_NUMBER],
  myRequestTableFieldProperties[fieldMapping.PRODUCT_CODE_REQUEST],
  myRequestTableFieldProperties[fieldMapping.DOC_TYPE_CODE],
  myRequestTableFieldProperties[fieldMapping.CHANGE_TYPE],
  myRequestTableFieldProperties[fieldMapping.CURRENT_GAC_DATE],
  myRequestTableFieldProperties[fieldMapping.CURRENT_GAC_REASON_CODE],
  myRequestTableFieldProperties[fieldMapping.PROPOSED_GAC_DATE_REQUEST],
  myRequestTableFieldProperties[fieldMapping.PROPOSED_GAC_REASON_CODE_REQUEST],
  myRequestTableFieldProperties[fieldMapping.VENDOR_CODE_REQUEST],
  myRequestTableFieldProperties[fieldMapping.DESTINATION_COUNTRY_CODE_REQUEST]
];
export const gacUpdateTableHeader = [
  gacUpdateTableFieldProperties[fieldMapping.PURCHASE_ORDER_NUMBER],
  gacUpdateTableFieldProperties[fieldMapping.PO_LINE_ITEM_NUMBER],
  gacUpdateTableFieldProperties[fieldMapping.SCHEDULE_LINE_ITEM_NUMBER],
  gacUpdateTableFieldProperties[fieldMapping.SIZE_DESC],
  gacUpdateTableFieldProperties[fieldMapping.PO_GAC],
  gacUpdateTableFieldProperties[fieldMapping.GAC_REASON_CODE],
  gacUpdateTableFieldProperties[fieldMapping.PURCHASING_DOCUMENT_TYPE_CODE],
  gacUpdateTableFieldProperties[fieldMapping.PRODUCT_CODE],
  gacUpdateTableFieldProperties[fieldMapping.MRGAC],
  gacUpdateTableFieldProperties.proposedGacDate,
  gacUpdateTableFieldProperties.proposedGacReasonCode,
  gacUpdateTableFieldProperties.proposedGRCUseCaseCode
];
export const gacEventHeader = [
  gacEventTableFieldProperties.eventCreateTS,
  gacEventTableFieldProperties.fileName,
  gacEventTableFieldProperties.eventEntryPoint,
  gacEventTableFieldProperties.eventStatus,
  gacEventTableFieldProperties.receivedItems,
  gacEventTableFieldProperties.errorsFoundCount,
  gacEventTableFieldProperties.eventErrorList
];
export const fileDownloadPoPdfEventHeader = [
  fileDownloadPOPDFTableFieldProperties.eventCreateTS,
  fileDownloadPOPDFTableFieldProperties.eventStatus,
  fileDownloadPOPDFTableFieldProperties.totalReceivedItemsCount,
  fileDownloadPOPDFTableFieldProperties.totalErrorCount,
  fileDownloadPOPDFTableFieldProperties.pdfDownloadLink,
  fileDownloadPOPDFTableFieldProperties.eventErrorList
];

export const fileDownloadPOSearchEventHeader = [
  fileDownloadPOSearchTableFieldProperties.eventCreateTS,
  fileDownloadPOSearchTableFieldProperties.eventStatus,
  fileDownloadPOSearchTableFieldProperties.pdfDownloadLink
];

export const fileDownloadReportEventHeader = [
  fileDownloadReportTableFieldProperties.eventCreateTS,
  fileDownloadReportTableFieldProperties.eventUpdateTS,
  fileDownloadReportTableFieldProperties.eventDescription,
  fileDownloadReportTableFieldProperties.eventStatus,
  fileDownloadReportTableFieldProperties.fileName
];
export const fileDownloadSireEventHeader = [
  fileDownloadSireTableFieldProperties.eventCreateTS,
  fileDownloadSireTableFieldProperties.eventStatus,
  fileDownloadSireTableFieldProperties.eventType,
  fileDownloadSireTableFieldProperties.totalReceivedItems,
  fileDownloadSireTableFieldProperties.totalErrorCount,
  fileDownloadSireTableFieldProperties.pdfDownloadLink,
  fileDownloadSireTableFieldProperties.eventErrorList
];
export const poRejectionEventHeader = [
  poRejectionEventTableFieldProperties.fileName,
  poRejectionEventTableFieldProperties.eventCreateTS,
  poRejectionEventTableFieldProperties.eventStatus,
  poRejectionEventTableFieldProperties.receivedItems,
  poRejectionEventTableFieldProperties.errorsFoundCount,
  poRejectionEventTableFieldProperties.eventErrorList
];

export const POFields = [
  ...dataTableHeader,
  ...dataTableCustomView,
  ...poReportsHeader,
  ...ppmReportsHeader,
  ...sireProfileTableHeader,
  ...customReportHeader
];

export const dataTableHeaderGAC = [
  gacEventErrorTableFieldProperties.poNumber,
  gacEventErrorTableFieldProperties.itemNumber,
  gacEventErrorTableFieldProperties.scheduleLineItemNumber,
  gacEventErrorTableFieldProperties.sizeDescription,
  gacEventErrorTableFieldProperties.proposedGACDate,
  gacEventErrorTableFieldProperties.proposedGACCode,
  gacEventErrorTableFieldProperties.currentGACDate,
  gacEventErrorTableFieldProperties.currentGACReasonCode,
  gacEventErrorTableFieldProperties.docType,
  gacEventErrorTableFieldProperties.productCode,
  gacEventErrorTableFieldProperties.mrgacDate,
  gacEventErrorTableFieldProperties.reasonForFailure
];
export const dataTableHeaderWIP = [
  wipTableFieldProperties.poNumber,
  wipTableFieldProperties.itemNumber,
  wipTableFieldProperties.wipEventCode,
  wipTableFieldProperties.expectedDate,
  wipTableFieldProperties.actualDate,
  wipTableFieldProperties.reasonForFailure
];
export const dataTableHeaderPO = [
  poEventErrorTableFieldProperties.poNumber,
  poEventErrorTableFieldProperties.itemNumber,
  poEventErrorTableFieldProperties.docType,
  poEventErrorTableFieldProperties.productCode,
  poEventErrorTableFieldProperties.currentGACDate,
  poEventErrorTableFieldProperties.currentGACReasonCode,
  poEventErrorTableFieldProperties.mrgacDate,
  poEventErrorTableFieldProperties.vendorCode,
  poEventErrorTableFieldProperties.reasonForFailure
];

export const dataTableHeaderPORejection = [
  rejectionEventErrorTableFieldProperties.poNumber,
  rejectionEventErrorTableFieldProperties.itemNumber,
  rejectionEventErrorTableFieldProperties.poRejectionCode,
  rejectionEventErrorTableFieldProperties.poRejectionDescription,
  rejectionEventErrorTableFieldProperties.reasonForFailure
];

export const pmoDecTableHeader = [
  pmoDecTableFieldProperties.factoryCode,
  pmoDecTableFieldProperties.managingOffice,
  pmoDecTableFieldProperties.managingUserID,
  pmoDecTableFieldProperties.managingUserEmail,
  pmoDecTableFieldProperties.managingUser,
  pmoDecTableFieldProperties.modifiedBy,
  pmoDecTableFieldProperties.modifiedDate
];

export const gacReasonUsecasesTableHeader = [
  gacReasonUsecasesFieldProperties[gacReasonUsecasesFieldMapping.GAC_REASON_USESCASES_REASON_CODE],
  gacReasonUsecasesFieldProperties[gacReasonUsecasesFieldMapping.GAC_REASON_USESCASES_DIVISION],
  gacReasonUsecasesFieldProperties[gacReasonUsecasesFieldMapping.GAC_REASON_USESCASES_CODE],
  gacReasonUsecasesFieldProperties[
    gacReasonUsecasesFieldMapping.GAC_REASON_USESCASES_CODE_DESCRIPTION
  ],
  gacReasonUsecasesFieldProperties[gacReasonUsecasesFieldMapping.GAC_REASON_USESCASES_STATUS],
  gacReasonUsecasesFieldProperties[
    gacReasonUsecasesFieldMapping.GAC_REASON_USESCASES_LAST_UPDATED_BY
  ],
  gacReasonUsecasesFieldProperties[
    gacReasonUsecasesFieldMapping.GAC_REASON_USESCASES_LAST_UPDATED_DATE
  ]
];

export const geoConfigTableHeader = [
  geoGlobalFieldProperties.ruleName,
  geoGlobalFieldProperties.destinationCountryCode,
  geoGlobalFieldProperties.purchaseGroup,
  geoGlobalFieldProperties.division,
  geoGlobalFieldProperties.docType,
  geoGlobalFieldProperties.plantCode,
  geoGlobalFieldProperties.email,
  geoGlobalFieldProperties.emailType,
  geoGlobalFieldProperties.name,
  geoGlobalFieldProperties.lastEditedBy,
  geoGlobalFieldProperties.lastEditTimeStamp
];

export const geoEmailGroupConfigTableHeader = [
  geoEmailGroupFieldProperties.emailID,
  geoEmailGroupFieldProperties.geoEmailList,
  geoEmailGroupFieldProperties.lastModifiedBy,
  geoEmailGroupFieldProperties.lastModifiedDate
];

export const dataTableHeaderPOPdf = [
  fileDownloadPOPDFTableFieldProperties.poNumber,
  fileDownloadPOPDFTableFieldProperties.reason
];

export const dataTableHeaderErrorSirePOSearch = [
  fileDownloadSireTableFieldProperties.id,
  fileDownloadSireTableFieldProperties.reason
];

export const dataTableHeaderSireInstruction = [
  fileDownloadSireTableFieldProperties.profileID,
  fileDownloadSireTableFieldProperties.shipToId,
  fileDownloadSireTableFieldProperties.plantId,
  fileDownloadSireTableFieldProperties.ttmi,
  fileDownloadSireTableFieldProperties.division,
  fileDownloadSireTableFieldProperties.buyGroup,
  fileDownloadSireTableFieldProperties.mode,
  fileDownloadSireTableFieldProperties.originCountry,
  fileDownloadSireTableFieldProperties.destCountry,
  fileDownloadSireTableFieldProperties.reason
];

export const dataTableheaderConversationCategoryCodes = [
  conversationCategoryCodesFieldProperties.ConversationCode,
  conversationCategoryCodesFieldProperties.conversationCategory,
  conversationCategoryCodesFieldProperties.ConversationDescription,
  conversationCategoryCodesFieldProperties.modifiedBy,
  conversationCategoryCodesFieldProperties.modifiedDate
];

export const gacReasonCodeTableHeader = [
  gacReasonCodeFieldProperties[gacReasonCodeFieldMapping.GAC_REASON_CODE],
  gacReasonCodeFieldProperties[gacReasonCodeFieldMapping.GAC_REASON_CODE_DESCRIPTION],
  gacReasonCodeFieldProperties[gacReasonCodeFieldMapping.GAC_REASON_CODE_ISACTIVE],
  gacReasonCodeFieldProperties[gacReasonCodeFieldMapping.GAC_MODIFIED_BY],
  gacReasonCodeFieldProperties[gacReasonCodeFieldMapping.GAC_MODIFY_DATE]
];

export const airFreightOverrideheader = [
  airFreightOverrideFieldProperties.poNumber,
  airFreightOverrideFieldProperties.lineItemNumber,
  airFreightOverrideFieldProperties.user,
  airFreightOverrideFieldProperties.userFactory,
  airFreightOverrideFieldProperties.geoCode,
  airFreightOverrideFieldProperties.pmoCode,
  airFreightOverrideFieldProperties.actualNAF,
  airFreightOverrideFieldProperties.actualFAF,
  airFreightOverrideFieldProperties.proposedNAF,
  airFreightOverrideFieldProperties.proposedFAF,
  airFreightOverrideFieldProperties.requestorComments,
  airFreightOverrideFieldProperties.overrideStatus,
  airFreightOverrideFieldProperties.attachments,
  airFreightOverrideFieldProperties.approverComments
];

export const airFreightStatusUpdateheader = [
  airFreightOverrideFieldProperties.poNumber,
  airFreightOverrideFieldProperties.lineItemNumber,
  airFreightOverrideFieldProperties.user,
  airFreightOverrideFieldProperties.userFactory,
  airFreightOverrideFieldProperties.geoCode,
  airFreightOverrideFieldProperties.pmoCode,
  airFreightOverrideFieldProperties.actualNAF,
  airFreightOverrideFieldProperties.actualFAF,
  airFreightOverrideFieldProperties.proposedNAF,
  airFreightOverrideFieldProperties.proposedFAF,
  airFreightOverrideFieldProperties.requestorComments,
  airFreightOverrideFieldProperties.attachments,
  airFreightOverrideFieldProperties.overrideStatus,
  airFreightOverrideFieldProperties.approverComments
];

export const featureFlagTableHeader = [
  featureFlagFieldProperties.featureName,
  featureFlagFieldProperties.status,
  featureFlagFieldProperties.groups,
  featureFlagFieldProperties.factoryAffiliations,
  featureFlagFieldProperties.startTime,
  featureFlagFieldProperties.endTime,
  featureFlagFieldProperties.dependencyFeatures,
  featureFlagFieldProperties.lastUpdatedBy,
  featureFlagFieldProperties.lastUpdatedAt
];
