/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import { initialLineSummaryFieldsState, lineSummaryReportFieldList } from '../../constants/staticData';
import lineSummaryReportFieldMapping from '../../constants/fieldMappings/lineSummaryReportFieldMapping';
import lineSummaryReportsHeader from '../../constants/lineSummaryReportsHeader';
import getflattenResponse, { cryptoRand } from '../../helpers/common';
import appConfig from '../../appConfig';
import {
  toggleSpinner, updateDialogStates, togglePaginationSpinner
} from '../dialogStates/dialogStatesSlice';
import {
  getFieldsList,
  getRequestedFilterValuesInLineItemResults,
  getSelectedFilterValues,
  getFiltersInResponseRequestBody,
  getSelectedFilterValuesFromOpMetrics,
  setFieldsData,
  getRequestedFilterValues,
  getLineItemSelectedFilterKeys,
  getLineItemModifiedSelectedFilters
} from './lineSummaryHelper';
import {
  getFilterValuesFromResponse, mapInputFieldsToRequest,
  mapRequestToInputFields,
  checkForColumnData,
  mapFiltersToRequest,
  sortedFilterData,
  setFieldItemVas,
  setValidFields
} from '../../helpers/dataHelpers';
import { showForTypePayload } from '../../helpers/envServer';
import {
  settingsActions
} from '../profileSettings/settingSlice';
import lineSummaryReportFieldProperties from '../../constants/fieldProperties/lineSummaryReportFieldProperties';

const name = 'lineSummaryReport';

function createInitialState() {
  return {
    // initial state of request reducer
    isSavedSearchModified: false,
    lineSummaryReportRequestBody: null,
    lineSummaryReportInputFieldData: { ...initialLineSummaryFieldsState },
    lineSummaryReportSelectedFields: [],
    lineSummaryReportBookmarkName: '',
    lineSummaryReportBookmarkId: '',
    requestError: false,
    // result reducer
    lineSummaryReportResultData: {},
    lineSummaryReportSearchId: '',
    lineSummaryReportTotalCount: 0,
    lineSummaryReportNextResults: {},
    lineSummaryReportPrevResults: {},
    page: 0,
    rowsPerPage: 100,
    lineSummaryReportSearchPanelId: '',
    // filter
    lineSummaryReportFilterId: 'none',
    filterDropdownValues: {},
    selectedFilters: {
      [lineSummaryReportFieldMapping.LINE_SUMMARY_FX_ADJ_REQUIRED_INDICATOR]: null,
      [lineSummaryReportFieldMapping.LINE_SUMMARY_PLANT_CODE]: null,
      [lineSummaryReportFieldMapping.LINE_SUMMARY_FOB_MISMATCH_INDICATOR]: null,
      [lineSummaryReportFieldMapping.LINE_SUMMARY_SIZE_MISMATCH_ID]: null,
      [lineSummaryReportFieldMapping.LINE_SUMMARY_CUSTOMER_REQ_DATE]: null,
      [lineSummaryReportFieldMapping.LINE_SUMMARY_R3_DOC_TYPE]: null,
      [lineSummaryReportFieldMapping.LINE_SUMMARY_ITEM_STATUS]: null,
      [lineSummaryReportFieldMapping.LINE_SUMMARY_PO_REJECTION_CODE]: null,
      [lineSummaryReportFieldMapping.LINE_SUMMARY_CREATED_BY]: null,
      [lineSummaryReportFieldMapping.LINE_SUMMARY_TRANSPORT_MODE]: null,
      [lineSummaryReportFieldMapping.LINE_SUMMARY_GRC_USECASE_CODE]: null
    }
  };
}
export const initialState = createInitialState();

function createReducers() {
  // request reducer
  function updateLineSummaryReportRequestBody(state: Object, action: Object) {
    const requestExist = JSON.stringify(state.lineSummaryReportRequestBody);
    return {
      ...state,
      lineSummaryReportRequestBody: action.payload,
      isSavedSearchModified: Boolean(
        state.lineSummaryReportBookmarkId && requestExist !== JSON.stringify(action.payload)
      )
    };
  }

  function updateIsSavedSearchModified(state: Object, action: Object) {
    return {
      ...state,
      isSavedSearchModified: Boolean(action.payload)
    };
  }

  function updateLineSummaryReportRequestBodyOnSameBookmark(state: Object, action: Object) {
    return {
      ...state,
      lineSummaryReportRequestBody: action.payload
    };
  }
  function updateLineSummaryReportFields(state: Object, action: Object) {
    return {
      ...state, lineSummaryReportSelectedFields: action.payload
    };
  }
  function updateLineSummaryReportInputValues(state: Object, action: Object) {
    return {
      ...state, lineSummaryReportInputFieldData: action.payload
    };
  }
  function updateLineSummaryOffsetInRequest(state: Object, action: Object) {
    const updatedBody = { ...state.lineSummaryReportRequestBody, offset: action.payload };
    return { ...state, lineSummaryReportRequestBody: updatedBody };
  }
  function resetLineSummaryReportInputData(state: Object) {
    return {
      ...state,
      lineSummaryReportInputFieldData: { ...initialLineSummaryFieldsState }
    };
  }
  function updateCollabBookmarkId(state: Object, action: Object) {
    return {
      ...state, isSavedSearchModified: false, lineSummaryReportBookmarkId: action.payload
    };
  }
  function updateCollabBookmarkName(state: Object, action: Object) {
    return {
      ...state, isSavedSearchModified: false, lineSummaryReportBookmarkName: action.payload
    };
  }
  function resetLineSummaryReportRequest(state: Object) {
    return {
      // ...initialState // correct
      ...state,
      isSavedSearchModified: false,
      lineSummaryReportRequestBody: null,
      lineSummaryReportInputFieldData: { ...initialLineSummaryFieldsState },
      lineSummaryReportSelectedFields: [],
      lineSummaryReportBookmarkName: '',
      lineSummaryReportBookmarkId: '',
      requestError: false
    };
  }
  function updateLineItemReportFiltersRequest(state: Object, action: Object) {
    const updatedBody = { ...state.lineSummaryReportRequestBody, filter: action.payload };
    return {
      ...state,
      lineSummaryReportRequestBody: updatedBody,
      isSavedSearchModified: Boolean(state.lineSummaryReportBookmarkName)
    };
  }
  function clearSavedLineSummaryReport(state: Object) {
    return {
      ...state,
      isSavedSearchModified: false,
      lineSummaryReportBookmarkId: '',
      lineSummaryReportBookmarkName: ''
    };
  }
  function resetLineSummaryRequestForClearSearch(state: Object) {
    return {
      ...state,
      lineSummaryReportRequestBody: null
    };
  }
  // resultreducer
  function lineSummaryReportOffset(state: Object, action: Object) {
    return {
      ...state,
      lineSummaryReportResultData: action?.payload,
      lineSummaryReportTotalCount: action?.payload?.pages?.totalResources,
      selectedRecordsCount: 0,
      lineSummaryReportNextResults: null,
      lineSummaryReportPrevResults: null
    };
  }
  function updateLineSummaryPage(state: Object, action: Object) {
    return {
      ...state,
      page: action.payload?.page ? action.payload?.page : action.payload
    };
  }
  function updateLineSummaryRowsPerPage(state: Object, action: Object) {
    return {
      ...state,
      rowsPerPage: action.payload?.page ? action.payload?.page : action.payload
    };
  }
  function updateLineSummaryReportResults(state: Object, action: Object) {
    return {
      ...state,
      lineSummaryReportResultData: action.payload,
      lineSummaryReportSearchId: cryptoRand().toString().substr(2, 8),
      lineSummaryReportTotalCount: action?.payload?.pages?.totalResources,
      selectedRecordsCount: 0,
      lineSummaryReportNextResults: null,
      lineSummaryReportPrevResults: null
    };
  }
  function updateLineSummaryReportSearchId(state: Object) {
    return {
      ...state, lineSummaryReportSearchId: cryptoRand().toString().substring(2, 8)
    };
  }
  function resetLineSummarySearchPanelId(state: Object) {
    return {
      ...state, lineSummaryReportSearchPanelId: cryptoRand().toString().substring(2, 8)
    };
  }
  function updateLineSummaryReportNextResult(state: Object, action: Object) {
    const payload = {
      data: getflattenResponse(action.payload.currentResultSet),
      dataNext: getflattenResponse(action.payload.response.data)
    };
    return {
      ...state,
      lineSummaryReportResultData: payload?.data,
      lineSummaryReportNextResults: payload?.dataNext,
      lineSummaryReportTotalPages: payload?.data?.pages?.totalPages,
      lineSummaryReportTotalCount: payload?.data?.pages?.totalResources
    };
  }
  function appendNextResultSet(state: Object) {
    const payload = {
      dataPrev: state.lineSummaryReportResultData,
      data: state.lineSummaryReportNextResults
    };
    return {
      ...state,
      lineSummaryReportResultData: payload.data,
      lineSummaryReportPrevResults: payload.dataPrev,
      lineSummaryReportNextResults: null
    };
  }
  function updateLineSummaryReportPrevResult(state: Object, action: Object) {
    const payload = {
      data: getflattenResponse(action.payload.currentResultSet),
      dataPrev: getflattenResponse(action.payload.response.data)
    };
    return {
      ...state,
      lineSummaryReportResultData: payload.data,
      lineSummaryReportPrevResults: payload.dataPrev,
      lineSummaryReportTotalPages: payload?.data?.pages?.totalPages,
      lineSummaryReportTotalCount: payload?.data?.pages?.totalResources
    };
  }
  function appendPrevResultSet(state: Object) {
    const payload = {
      data: state.lineSummaryReportPrevResults,
      dataNext: state.lineSummaryReportResultData
    };
    return {
      ...state,
      lineSummaryReportResultData: payload.data,
      lineSummaryReportNextResults: payload.dataNext,
      lineSummaryReportPrevResults: null
    };
  }
  function resetLineSummaryReportResults(state: Object) {
    return {
      // ...initialState // correct
      ...state,
      lineSummaryReportResultData: {},
      lineSummaryReportSearchId: '',
      lineSummaryReportTotalCount: 0,
      lineSummaryReportNextResults: {},
      lineSummaryReportPrevResults: {},
      page: 0,
      rowsPerPage: 100,
      lineSummaryReportSearchPanelId: ''
    };
  }
  function updateLineSummaryContinuationToken(state: Object, action: Object) {
    return {
      ...state,
      continuationToken: action.payload
    };
  }
  function setLineSummaryReportFilterId(state: Object) {
    return {
      ...state,
      lineSummaryReportFilterId: cryptoRand().toString().substr(2, 8)
    };
  }

  function bulkUpdateLineItemReportFilterValues(state: Object, action: Object) {
    if (!action.payload.filter) return { ...state };
    const modifiedSelectedFilters = {
      ...state.selectedFilters,
      [action.payload.filter]: action.payload.values
    };
    return { ...state, selectedFilters: modifiedSelectedFilters };
  }
  function bulkToggleFilters(state: Object, action: Object) {
    const modifiedSelectedFilters = { ...state.selectedFilters };
    Object.keys(action.payload).forEach((filter) => {
      if (action.payload[filter]) {
        if (!modifiedSelectedFilters[filter]) modifiedSelectedFilters[filter] = [];
      } else modifiedSelectedFilters[filter] = null;
    });
    return { ...state, selectedFilter: modifiedSelectedFilters };
  }
  function updateLineItemReportFiltersInResposne(state: Object, action: Object) {
    const updatedBody = { ...state.body, filtersInResponse: action.payload };
    return { ...state, body: updatedBody };
  }
  function bulkUpdateSelectedLineItemFilters(state: Object, action: Object) {
    if (action.payload instanceof Array) {
      const modifiedSelectedFilters = { ...state.selectedFilters };
      Object.keys(modifiedSelectedFilters).forEach((filter: string) => {
        if (action.payload.includes(filter)) {
          if (!modifiedSelectedFilters[filter]) modifiedSelectedFilters[filter] = [];
        } else {
          modifiedSelectedFilters[filter] = null;
        }
      });
      return { ...state, selectedFilters: modifiedSelectedFilters };
    }
    if ((typeof action.payload === 'object') && action.payload.selectedFilters
        && Object.keys(action.payload.selectedFilters).length > 0) {
      const modifiedSelectedFilters = {
        ...state.selectedFilters,
        ...action.payload.selectedFilters
      };
      return { ...state, selectedFilters: modifiedSelectedFilters };
    }
    return { ...state, selectedFilters: initialState.selectedFilters };
  }
  function clearFilters(state: Object) {
    const selectedFilters = {};
    Object.keys(state.selectedFilters).forEach((filter) => {
      selectedFilters[filter] = null;
    });
    return {
      ...state,
      filterDropdownValues: {},
      selectedFilters
    };
  }
  function resetLineSummaryReportSelectedFiltersToInitialState(state: Object) {
    const selectedFilters = {};
    Object.keys(state.selectedFilters).forEach((filter) => {
      if (state.selectedFilters[filter]) {
        selectedFilters[filter] = [];
      } else {
        selectedFilters[filter] = null;
      }
    });
    return {
      ...state,
      selectedFilters
    };
  }
  function resetLineSummaryFilterDropdownValue(state: Object) {
    return {
      ...state,
      filterDropdownValues: {}
    };
  }
  function updateReportFilterDropDownValues(state: Object, action: Object) {
    if (
      action.payload
      && Object.keys(action.payload).length > 0
    ) {
      return {
        ...state,
        filterDropdownValues: sortedFilterData(action.payload)
      };
    }
    return { ...state };
  }

  return {
    updateLineSummaryReportRequestBody,
    updateIsSavedSearchModified,
    updateLineSummaryReportRequestBodyOnSameBookmark,
    updateLineSummaryReportFields,
    updateLineSummaryReportInputValues,
    updateLineSummaryOffsetInRequest,
    resetLineSummaryReportInputData,
    updateCollabBookmarkId,
    updateCollabBookmarkName,
    resetLineSummaryReportRequest,
    updateLineItemReportFiltersInResposne,
    updateLineItemReportFiltersRequest,
    clearSavedLineSummaryReport,
    resetLineSummaryRequestForClearSearch,
    // result
    lineSummaryReportOffset,
    updateLineSummaryPage,
    updateLineSummaryRowsPerPage,
    updateLineSummaryReportResults,
    updateLineSummaryReportSearchId,
    resetLineSummarySearchPanelId,
    updateLineSummaryReportNextResult,
    appendNextResultSet,
    updateLineSummaryReportPrevResult,
    appendPrevResultSet,
    resetLineSummaryReportResults,
    updateLineSummaryContinuationToken,
    // filter
    setLineSummaryReportFilterId,
    bulkUpdateLineItemReportFilterValues,
    bulkToggleFilters,
    bulkUpdateSelectedLineItemFilters,
    clearFilters,
    resetLineSummaryReportSelectedFiltersToInitialState,
    resetLineSummaryFilterDropdownValue,
    updateReportFilterDropDownValues
  };
}

export const reducers = createReducers();

export const slice = createSlice({ name, initialState, reducers });

export const actions = { ...slice.actions };

function createExtraActions() {
  function fetchPOReports() {
    return createAsyncThunk(
      `${name}/fetchPOReports`,
      async ({
        data, callback, requestBody = {},
        shouldRequestFilterValues = true
      }, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));

        const lineItemSecondaryFields = [];
        getFieldsList(lineItemSecondaryFields);

        const state = getState();
        const {
          selectedColumnOrderOptions,
          selectedFilterOptions: filterOptions,
          rowPerPageOption: rowsPerPageOption
        } = state.searchpaneldata || {};

        const columnOrderOptions = [...selectedColumnOrderOptions];
        if (checkForColumnData(state, true)) {
          lineSummaryReportsHeader.forEach((headRow: Object) => {
            if (headRow.includeInRequest) {
              columnOrderOptions.push(headRow.primary);
              if (headRow.secondary && !columnOrderOptions.includes(headRow.secondary)) {
                columnOrderOptions.push(headRow.secondary);
              }
            }
          });
        }
        const fieldsForRequest = checkForColumnData(state, true)
          ? columnOrderOptions
          : lineItemSecondaryFields;
        let lineItemRequestData = {};
        if (data) {
          lineItemRequestData = mapInputFieldsToRequest(data, 'lineSummaryReport');
          const appliedSearchField = [...new Set(lineItemRequestData
            .map((field) => field.fieldName))];
          dispatch(actions.updateLineSummaryReportInputValues(data));
          dispatch(actions.updateLineSummaryReportFields(lineSummaryReportFieldList
            .map((field) => field.key)
            .filter((field) => appliedSearchField.includes(field))));
        } else {
          lineItemRequestData = state.lineSummaryReport.lineSummaryReportRequestBody.search;
        }
        const filterRequest = Object.keys(requestBody).length
          ? state.lineSummaryReport.lineSummaryReportRequestBody.filter
          : [];
        let requestedBody = (state.lineSummaryReport.lineSummaryReportRequestBody) ? {
          ...state.lineSummaryReport.lineSummaryReportRequestBody,
          search: lineItemRequestData,
          filter: filterRequest,
          offset: '0',
          ...requestBody
        } : {
          fields: fieldsForRequest,
          search: lineItemRequestData,
          filter: Object.keys(requestBody).length
            ? state.lineSummaryReport.lineSummaryReportRequestBody.filter
            : [],
          count: appConfig.SearchThresholdLimit,
          offset: '0',
          ...requestBody
        };
        requestedBody = setValidFields(lineSummaryReportFieldProperties, requestedBody);
        requestedBody = setFieldItemVas(requestedBody);

        if (shouldRequestFilterValues && !filterOptions) {
          getRequestedFilterValuesInLineItemResults(requestedBody, state);
        } else {
          getSelectedFilterValues(requestedBody, state);
        }
        const payload = {
          ...requestedBody,
          fields: [
            ...requestedBody.fields,
            ...appConfig.includeFieldsLineSummary.filter(
              (item) => !requestedBody.fields.includes(item)
            )
          ]
        };
        request({
          api: 'lineSummaryReportSearch',
          method: 'post',
          data: showForTypePayload ? { ...payload, type: 'lineItem' } : payload,
          cancellable: true
        }, dispatch).then((response) => {
          dispatch(actions.updateLineSummaryReportRequestBody(requestedBody));
          dispatch(toggleSpinner(false));
          callback(response.data);
          if (shouldRequestFilterValues) {
            dispatch(actions.updateReportFilterDropDownValues(
              getFilterValuesFromResponse(response.data.filters || [])
            ));
          }
          if (shouldRequestFilterValues && (filterOptions && filterOptions.length > 0)) {
            dispatch(actions.bulkUpdateSelectedLineItemFilters(filterOptions));
          }
          dispatch(actions.updateLineSummaryRowsPerPage(rowsPerPageOption));
          dispatch(actions.updateLineSummaryReportResults(getflattenResponse(response.data)));
        }).catch((error) => {
          dispatch(toggleSpinner(false));
          callback(null, error);
        });
      }
    );
  }

  function exportPOReport() {
    return createAsyncThunk(
      `${name}/exportPOReport`,
      async ({
        callback, filetype, chart
      }, { getState, dispatch }) => {
        const state = getState();
        dispatch(toggleSpinner(true));
        const searchRequestBody = {
          ...state.lineSummaryReport.lineSummaryReportRequestBody
        };
        const requestBody = {
          search: [...(searchRequestBody.search) || []],
          fields: [...(searchRequestBody.fields) || []].filter((field: string) => (
            !appConfig.excludedLineSummaryFieldsInExportedFile.includes(field)
          )),
          filter: [...(searchRequestBody.filter) || []],
          searchType: searchRequestBody.searchType,
          fileFormat: filetype,
          type: 'lineItem'
        };

        const graphSummary = {};
        if (chart) {
          graphSummary.graphName = chart;
          graphSummary.data = state.OperationalMetrics[chart];// need to check after implementation
        }
        const chartRequestBody = {
          searchCriteria: {
            search: requestBody.search,
            fields: requestBody.fields,
            filter: requestBody.filter,
            searchType: requestBody.searchType,
            fileFormat: filetype
          },
          graphSummary
        };
        const requestApi = 'lineSummaryReportExportLargeFile';
        const requestMetricsApi = 'metricsExportLargeFile';
        request({
          api: chart ? requestMetricsApi : requestApi,
          method: 'post',
          data: chart ? chartRequestBody : requestBody
        }, dispatch).then((response) => {
          callback(response);
          dispatch(toggleSpinner(false));
        }).catch((error) => {
          callback(null, error);
          dispatch(toggleSpinner(false));
        });
      }
    );
  }
  function fetchSavedLineSummaryReportResults() {
    return createAsyncThunk(
      `${name}/fetchSavedLineSummaryReportResults`,
      async ({
        lineSummaryReportRequestData, callback, isOperationalCall
      }, { getState, dispatch }) => {
        const requestBody = { ...lineSummaryReportRequestData.searchCriteria, offset: '0' };
        const state = getState();
        const {
          selectedFilterOptions: filterOptions,
          rowPerPageOption: rowsPerPageOption
        } = state.searchpaneldata || {};
        dispatch(toggleSpinner(true));
        dispatch(updateDialogStates({ executeBookmarkSpinner: true }));

        const filterValuesOptByUser = getSelectedFilterValuesFromOpMetrics(filterOptions);

        const filtersInResponseData = getFiltersInResponseRequestBody(
          lineSummaryReportRequestData.searchCriteria.filter
        );
        // to include user default filters only for LISR
        if (filterOptions && filterOptions.length > 0 && !isOperationalCall) {
          filtersInResponseData.push(...filterValuesOptByUser);
        }
        const filteredData = [...new Set(filtersInResponseData
          .map(({ primary }) => primary).filter(Boolean))]
          .map((primary) => filtersInResponseData.find(({ primary: p }) => p === primary));

        const savedSearchRequestBody = {
          ...requestBody,
          filtersInResponse: filteredData
        };

        let requestData = { ...savedSearchRequestBody };
        requestData = setValidFields(lineSummaryReportFieldProperties, requestData);
        const fieldsData = [...requestData.fields];
        requestData = setFieldItemVas(requestData);
        setFieldsData(isOperationalCall, fieldsData);
        requestData = { ...requestData, fields: fieldsData };
        const payloadModified = { ...requestData };
        if (lineSummaryReportRequestData.id) {
          payloadModified.savedSearchID = lineSummaryReportRequestData.id;
        }
        request({
          api: 'lineSummaryReportSearch',
          method: 'post',
          cancellable: true,
          data: showForTypePayload ? { ...payloadModified, type: 'lineItem' } : payloadModified
        }, dispatch).then((response) => {
          const fields = mapRequestToInputFields(
            [...lineSummaryReportRequestData.searchCriteria.search]
          );
          const lineSummaryReportSearchFields = [...new Set(
            lineSummaryReportRequestData.searchCriteria.search.map((field) => field.fieldName)
          )];
          dispatch(actions.updateLineSummaryReportInputValues({
            ...fields
          }));
          dispatch(actions.updateLineSummaryReportRequestBody(requestData));
          dispatch(actions.updateLineSummaryReportFields(lineSummaryReportFieldList
            .map((field) => field.key)
            .filter((field) => lineSummaryReportSearchFields.includes(field))));
          dispatch(actions.updateLineSummaryReportResults(getflattenResponse(response.data)));
          dispatch(actions.updateLineSummaryReportSearchId());
          dispatch(actions.updateLineSummaryPage(0));
          dispatch(actions.updateLineSummaryRowsPerPage(Number(rowsPerPageOption)));
          dispatch(actions.updateCollabBookmarkId(lineSummaryReportRequestData.id));
          dispatch(actions.updateCollabBookmarkName(lineSummaryReportRequestData.name));
          dispatch(toggleSpinner(false));
          dispatch(updateDialogStates({ executeBookmarkSpinner: false }));
          dispatch(actions.clearFilters());
          const filterValuesFromResponse = getFilterValuesFromResponse(response.data.filters || []);
          dispatch(actions.updateReportFilterDropDownValues({
            ...state.lineSummaryReport.filterDropdownValues,
            ...filterValuesFromResponse
          }));
          const selectedFilters = {};
          const filters = lineSummaryReportRequestData?.searchCriteria?.filter;
          if (filters && filters.length > 0) {
            filters.forEach((filter) => {
              const availableFilters = filterValuesFromResponse[filter.fieldName];
              selectedFilters[filter.fieldName] = filter.fieldValue
                .map((filterValue) => availableFilters
                  .find((availableFilter) => availableFilter.includes(filterValue)))
                .filter((filterValue) => filterValue);
            });
            dispatch(actions.bulkUpdateSelectedLineItemFilters({ selectedFilters }));
          } else {
            dispatch(actions.bulkUpdateSelectedLineItemFilters({ selectedFilters }));
          }
          if (filterOptions.length > 0) {
            const updatedFilterValues = response && response.data
                            && response.data.filters.map((key) => key.filterName);
            dispatch(actions.bulkUpdateSelectedLineItemFilters(updatedFilterValues));
          }
          dispatch(toggleSpinner(false));
          dispatch(updateDialogStates({ executeBookmarkSpinner: false }));
          if (callback) {
            callback(response);
          }
        }).catch((error) => {
          dispatch(toggleSpinner(false));
          dispatch(updateDialogStates({ executeBookmarkSpinner: false }));
          if (callback) {
            callback(null, error);
          }
        });
      }
    );
  }
  function fetchLineSummaryreportsResultsOffset() {
    return createAsyncThunk(
      `${name}/fetchLineSummaryreportsResultsOffset`,
      async (_, { getState, dispatch }) => {
        const state = getState();
        dispatch(toggleSpinner(true));
        if (
          state.lineSummaryReport.lineSummaryReportRequestBody
                    && state.lineSummaryReport.lineSummaryReportResultData
        ) {
          let updatedRequest = state.lineSummaryReport.lineSummaryReportRequestBody;
          updatedRequest = setFieldItemVas(updatedRequest);
          // delete updatedRequest.offset;
          updatedRequest = {
            ...updatedRequest,
            offset: '0'
          };
          delete updatedRequest.filtersInResponse;
          request({
            api: 'lineSummaryReportSearch',
            method: 'post',
            data: showForTypePayload ? { ...updatedRequest, type: 'lineItem' } : updatedRequest
          }, dispatch).then((response) => {
            dispatch(actions.lineSummaryReportOffset(getflattenResponse(response.data)));
            dispatch(toggleSpinner(false));
            dispatch(actions.resetLineSummarySearchPanelId());
          }).catch((error) => {
            console.log('Error: ', error);
            dispatch(toggleSpinner(false));
          });
        }
      }
    );
  }
  function lineSummaryReportDataOffset() {
    return createAsyncThunk(
      `${name}/lineSummaryReportDataOffset`,
      async (
        {
          linefetchLineSummaryreportsResultsOffset // todo on page
        }, { dispatch }
      ) => {
        dispatch(linefetchLineSummaryreportsResultsOffset());
      }
    );
  }
  function fetchLineSummaryReportNextResultSet() {
    return createAsyncThunk(
      `${name}/fetchLineSummaryReportNextResultSet`,
      async (_, { getState, dispatch }) => {
        const state = getState();
        if (
          state.lineSummaryReport.lineSummaryReportRequestBody
                && state.lineSummaryReport.lineSummaryReportResultData
                && state.lineSummaryReport.lineSummaryReportResultData.pages.next
        ) {
          const currentResultSet = state.lineSummaryReport.lineSummaryReportResultData;
          let updatedRequest = state.lineSummaryReport.lineSummaryReportRequestBody;
          const searchResultNextOffset = state.lineSummaryReport
            .lineSummaryReportResultData.pages.next;
          // delete updatedRequest.offset;
          const resNext = searchResultNextOffset.indexOf(',');
          updatedRequest = {
            ...updatedRequest,
            offset: searchResultNextOffset.slice(7, resNext)
          };
          let requestBodyWithoutFilters = { ...updatedRequest };
          requestBodyWithoutFilters = setFieldItemVas(requestBodyWithoutFilters);
          delete requestBodyWithoutFilters.filtersInResponse;
          dispatch(togglePaginationSpinner({ isFetchingNextData: true }));
          request({
            api: 'lineSummaryReportSearch',
            method: 'post',
            data: showForTypePayload ? { ...requestBodyWithoutFilters, type: 'lineItem' } : requestBodyWithoutFilters
          }, dispatch).then((response) => {
            dispatch(actions.updateLineSummaryReportRequestBodyOnSameBookmark(updatedRequest));
            dispatch(togglePaginationSpinner({ isFetchingNextData: false }));
            dispatch(toggleSpinner(false));
            dispatch(actions.updateLineSummaryReportNextResult({ response, currentResultSet }));
          }).catch((error) => {
            dispatch(togglePaginationSpinner({ isFetchingNextData: false }));
            console.log('NEXT Error ', error);
          });
        }
      }
    );
  }
  function reportsDataNextSet() {
    return createAsyncThunk(
      `${name}/reportsDataNextSet`,
      async (
        {
          linefetchLineSummaryReportNextResultSet
        }, { dispatch }
      ) => {
        dispatch(linefetchLineSummaryReportNextResultSet());
      }
    );
  }
  function fetchLineSummaryReportPrevResultSet() {
    return createAsyncThunk(
      `${name}/fetchLineSummaryReportPrevResultSet`,
      async (_, { getState, dispatch }) => {
        const state = getState();
        dispatch(togglePaginationSpinner({ isFetchingPrevData: true }));
        if (state.lineSummaryReport && state.lineSummaryReport.lineSummaryReportResultData
          && state.lineSummaryReport.lineSummaryReportResultData.pages.prev) {
          const currentResultSet = state.lineSummaryReport.lineSummaryReportResultData;
          let updatedRequest = state.lineSummaryReport.lineSummaryReportRequestBody;
          const searchResultPrevOffset = state.lineSummaryReport.lineSummaryReportResultData
            .pages.prev;
          // delete updatedRequest.offset;
          const res = searchResultPrevOffset.indexOf(',');
          updatedRequest = {
            ...updatedRequest,
            offset: searchResultPrevOffset.slice(7, res)
          };
          let requestBody = { ...updatedRequest };
          requestBody = setFieldItemVas(requestBody);
          delete requestBody.filtersInResponse;

          request({
            api: 'lineSummaryReportSearch',
            method: 'post',
            data: showForTypePayload ? { ...requestBody, type: 'lineItem' } : requestBody
          }, dispatch).then((response) => {
            dispatch(actions.updateLineSummaryReportRequestBodyOnSameBookmark(updatedRequest));
            dispatch(togglePaginationSpinner({ isFetchingPrevData: false }));
            dispatch(toggleSpinner(false));
            dispatch(actions.updateLineSummaryReportPrevResult({ response, currentResultSet }));
          }).catch((error) => {
            dispatch(togglePaginationSpinner({ isFetchingPrevData: false }));
            console.log('PREV Error ', error);
          });
        }
      }
    );
  }
  function reportsDataPrevSet() {
    return createAsyncThunk(
      `${name}/reportsDataPrevSet`,
      async (
        {
          linefetchLineSummaryReportPrevResultSet // todo on page
        }, { dispatch }
      ) => {
        dispatch(linefetchLineSummaryReportPrevResultSet());
      }
    );
  }
  function getGacReasonCode() {
    return createAsyncThunk(
      `${name}/getGacReasonCode`,
      async ({
        callback
      }, { dispatch }) => {
        const payload = {};
        dispatch(toggleSpinner(true));
        request({
          api: 'getGACReasonCode',
          method: 'get'
        }, dispatch).then((response) => {
          dispatch(toggleSpinner(false));
          payload.gacReasonCode = response.data;
          if (callback) {
            callback(response);
          }
        }).catch((error) => {
          dispatch(toggleSpinner(false));
          if (callback) {
            callback(null, error);
          }
        });
      }
    );
  }
  function applyFilters() {
    return createAsyncThunk(
      `${name}/applyFilters`,
      async ({
        payload,
        lineItemFilterCallback,
        shouldRequestLineItemFilterValues = false
      }, { getState, dispatch }) => {
        const state = getState();
        const selectedFilters = {
          ...state.lineSummaryReport.selectedFilters,
          [payload.filter]: payload.values
        };
        let requestBody = {
          ...state.lineSummaryReport.lineSummaryReportRequestBody,
          filter: mapFiltersToRequest(selectedFilters),
          offset: '0'
        };
        requestBody = setValidFields(lineSummaryReportFieldProperties, requestBody);
        const selectedFilterKeys = Object.keys(selectedFilters)
          .filter((filter) => selectedFilters[filter]);
        if (shouldRequestLineItemFilterValues && selectedFilterKeys.length > 0) {
          getRequestedFilterValues(requestBody, selectedFilterKeys);
        } else {
          delete requestBody.filtersInResponse;
        }

        dispatch(toggleSpinner(true));
        request({
          api: 'lineSummaryReportSearch',
          method: 'post',
          data: showForTypePayload ? { ...requestBody, type: 'lineItem' } : requestBody,
          cancellable: true
        }, dispatch).then((response) => {
          dispatch(actions.updateLineSummaryReportRequestBody(requestBody));
          dispatch(toggleSpinner(false));
          const flattenedResponse = getflattenResponse(response.data);
          dispatch(actions.updateLineSummaryReportResults(flattenedResponse));
          dispatch(actions.updateLineSummaryPage(0));
          dispatch(actions.setLineSummaryReportFilterId());
          dispatch(actions.bulkUpdateLineItemReportFilterValues(payload));
          if (shouldRequestLineItemFilterValues && selectedFilterKeys.length > 0) {
            dispatch(actions.updateLineItemReportFiltersInResposne(requestBody.filtersInResponse));
          }
          if (response.data.filters) {
            dispatch(actions.updateReportFilterDropDownValues(
              getFilterValuesFromResponse(response.data.filters)
            ));
          }
          if (lineItemFilterCallback) {
            lineItemFilterCallback(response);
          }
        }).catch((error) => {
          if (lineItemFilterCallback) {
            lineItemFilterCallback(null, error);
          }
          console.log(error);
          dispatch(toggleSpinner(false));
        });
      }
    );
  }
  function fetchUnfilteredPOResults() {
    return createAsyncThunk(
      `${name}/fetchUnfilteredPOResults`,
      async (_, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));
        const state = getState();
        let requestBody = {
          ...state.lineSummaryReport.lineSummaryReportRequestBody,
          filter: [],
          offset: '0'
        };
        requestBody = setValidFields(lineSummaryReportFieldProperties, requestBody);
        request({
          api: 'lineSummaryReportSearch',
          method: 'post',
          data: showForTypePayload ? { ...requestBody, type: 'lineItem' } : requestBody,
          cancellable: true
        }, dispatch).then((response) => {
          dispatch(toggleSpinner(false));
          dispatch(actions.clearFilters());
          dispatch(actions.resetLineSummarySearchPanelId());
          dispatch(actions.updateLineItemReportFiltersRequest([]));
          dispatch(actions.updateLineSummaryReportResults(getflattenResponse(response.data)));
        }).catch((error) => {
          console.log(error);
          dispatch(toggleSpinner(false));
        });
      }
    );
  }
  function aynscCallToDispatches() {
    return createAsyncThunk(
      `${name}/aynscCallToDispatches`,
      async (
        {
          response,
          filterDropdownValues,
          filtersInResponse,
          hasAppliedLineItemFiltersChanged,
          filtersToBeApplied,
          requestNewFiltersCallback
        },

        {
          dispatch
        }
      ) => {
        if (response.data.filters) {
          dispatch(actions.updateReportFilterDropDownValues({
            ...filterDropdownValues,
            ...getFilterValuesFromResponse(response.data.filters)
          }));
          dispatch(actions.updateLineItemReportFiltersInResposne(filtersInResponse));
        }
        if (hasAppliedLineItemFiltersChanged) {
          dispatch(actions.updateLineItemReportFiltersRequest(filtersToBeApplied));
          dispatch(actions.updateLineSummaryReportResults(getflattenResponse(response.data)));
          dispatch(actions.updateLineSummaryPage(0));
        }
        if (requestNewFiltersCallback) {
          requestNewFiltersCallback(response);
        }
      }
    );
  }

  function requestForNewFilters() {
    return createAsyncThunk(
      `${name}/requestForNewFilters`,
      async ({
        filtersSelected,
        enableCheckboxForDefault,
        requestNewFiltersCallback,
        TaynscCallToDispatches
      }, { getState, dispatch }) => {
        const state = getState();
        let requestBody = { ...state.lineSummaryReport.lineSummaryReportRequestBody };
        requestBody = setValidFields(lineSummaryReportFieldProperties, requestBody);
        const {
          selectedFilters,
          filterDropdownValues
        } = state.lineSummaryReport;
        const modifiedSelectedFilters = { ...selectedFilters };
        getLineItemModifiedSelectedFilters(modifiedSelectedFilters, filtersSelected);
        const lineItemFiltersToBeRequested = [];
        const filtersInResponse = [];
        let filtersToBeApplied = null;

        const selectedFilterKeys = Object.keys(modifiedSelectedFilters)
          .filter((filter) => modifiedSelectedFilters[filter]);
        const appliedFiltersKeys = selectedFilterKeys.filter((filter) => (
          modifiedSelectedFilters[filter] && modifiedSelectedFilters[filter].length > 0
        ));
        const hasAppliedLineItemFiltersChanged = (!(requestBody.filter || []).every((filter) => (
          appliedFiltersKeys.includes(filter.fieldName)
        )));

        if (hasAppliedLineItemFiltersChanged) {
          filtersToBeApplied = mapFiltersToRequest(modifiedSelectedFilters);
          requestBody.filter = [...filtersToBeApplied];
        }
        getLineItemSelectedFilterKeys(
          selectedFilterKeys,
          modifiedSelectedFilters,
          filterDropdownValues,
          lineItemFiltersToBeRequested,
          filtersInResponse
        );
        if (lineItemFiltersToBeRequested.length === 0) {
          delete requestBody.filtersInResponse;
        } else {
          requestBody.filtersInResponse = lineItemFiltersToBeRequested;
        }
        requestBody.offset = '0';
        const selectedFilterData = {
          selectedFilterListForLineItemReport: filtersSelected
        };
        if (enableCheckboxForDefault) {
          dispatch(settingsActions.userProfileSettings({
            requestBody: selectedFilterData
          }));
        }
        dispatch(toggleSpinner(true));

        request({
          api: 'lineSummaryReportSearch',
          method: 'post',
          data: showForTypePayload ? { ...requestBody, type: 'lineItem' } : requestBody,
          cancellable: true
        }, dispatch).then((response) => {
          dispatch(toggleSpinner(false));
          dispatch(actions.updateLineSummaryPage(0));
          dispatch(actions.setLineSummaryReportFilterId());
          dispatch(actions.resetLineSummarySearchPanelId());
          dispatch(actions.updateLineSummaryOffsetInRequest('0'));
          dispatch(actions.updateLineSummaryReportResults(getflattenResponse(response.data)));
          dispatch(actions.bulkUpdateSelectedLineItemFilters(filtersSelected));
          dispatch(TaynscCallToDispatches({
            response,
            filterDropdownValues,
            filtersInResponse,
            hasAppliedLineItemFiltersChanged,
            filtersToBeApplied,
            requestNewFiltersCallback
          }));
        }).catch((error) => {
          dispatch(toggleSpinner(false));
          if (requestNewFiltersCallback) {
            requestNewFiltersCallback(null, error);
          }
        });
      }
    );
  }

  return {
    fetchPOReports: fetchPOReports(),
    exportPOReport: exportPOReport(),
    fetchSavedLineSummaryReportResults: fetchSavedLineSummaryReportResults(),
    lineSummaryReportDataOffset: lineSummaryReportDataOffset(),
    fetchLineSummaryreportsResultsOffset: fetchLineSummaryreportsResultsOffset(),
    reportsDataNextSet: reportsDataNextSet(),
    fetchLineSummaryReportNextResultSet: fetchLineSummaryReportNextResultSet(),
    reportsDataPrevSet: reportsDataPrevSet(),
    fetchLineSummaryReportPrevResultSet: fetchLineSummaryReportPrevResultSet(),
    getGacReasonCode: getGacReasonCode(),
    applyFilters: applyFilters(),
    fetchUnfilteredPOResults: fetchUnfilteredPOResults(),
    requestForNewFilters: requestForNewFilters(),
    aynscCallToDispatches: aynscCallToDispatches()
  };
}

const extraActions = createExtraActions();

export const lineSummaryActions = { ...actions, ...extraActions };
export const lineSummaryReducer = slice.reducer;
