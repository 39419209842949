import {
  gacEventHeader, poRejectionEventHeader,
  fileDownloadPoPdfEventHeader,
  fileDownloadSireEventHeader,
  fileDownloadReportEventHeader
} from './constants/dataTableHeader';
import imagesPath from './constants/imagesPath';
import USERGROUP, { ALLOWED_USER_GROUP } from './constants/userGroup';

/**
 * App Configuration file
 * @author {Cognizant Technology Solutions}
 */
const appConfig = {
  maxRowsAllowedForBulkUpload: 7000,
  gacUpdateSearchThreshold: 10000,
  maxRetryCount: 3,
  SearchThresholdLimit: 1000,
  AfAdjustmentSearchThresholdLimit: 50,
  SearchRowsPerPageOptions: [100, 250, 500],
  SireRowsPerPageOptions: [50, 100, 500],
  RequestThresholdLimit: 1000,
  RequestRowsPerPageOptions: [10, 25, 50, 100],
  GacUpdateRowsPerPageOptions: [50, 100, 200],
  SettingsRowsPerPageOptions: [50, 100, 200],
  textLengthInfo: 50,
  apiTimeoutDuration: 205000,
  enableMultiDashboard: true,
  snackBarAutoCloseDuration: 15000,
  dateFormat: 'MM/dd/yyyy',
  dateAndTimeFormat: 'MM/dd/yyyy Thh/mm/ss',
  dateTimeFormat: 'yyyy/MM/dd Thh/mm/ss',
  excludedPOFieldsInExportedFile: [
    'id',
    'poLineItem.sizes',
    'tsUpdate',
    'isLine',
    // 'sizes.sizeVas',
    'poLine.itemTextDetail.textDetails',
    'sizes.sizeVas.valueAddedServiceInstructions',
    'poLine.itemVas.valueAddedServiceInstructions',
    'dpomAirFreight.isLocked'
  ],
  excludedPOReportFieldsInExportedFile: [
    'poData.isLine',
    'id',
    'poData.sizes.sizeId'
  ],
  excludedPPMReportFieldsInExportedFile: [
    'tsUpdate',
    'id',
    'sizes.sizeId'
  ],
  excludedLineSummaryFieldsInExportedFile: [
    'tsUpdate',
    'id',
    'sizes.sizeId'
  ],
  includeFieldsPPM: [
    'poLine.productCode'
  ],
  includeFieldsLineSummary: [
    'poLine.productCode'
  ],
  includeIsLockedInAF: [
    'dpomAirFreight.isLocked',
    'dpomAirFreight.faf',
    'dpomAirFreight.naf'
  ],
  includeFielsPoSearch: [
    'poHeader.vendorCode'
  ],
  includedPOFieldsInGacUpdate: [
    'sizes.sizeId',
    'poHeader.poNumber',
    'poLine.itemNumber',
    'manufacturing.initialGoodsAtConsolidatorReasonCode',
    'manufacturing.initialGoodsAtConsolidatorReasonDescription',
    'manufacturing.initialGoodsAtConsolidatorDate',
    'poHeader.poDocTypeCode',
    'poLine.productCode',
    'planning.mrgacDate',
    'poHeader.poDocTypeCode',
    'sizes.sizePo.sizeDescription',
    'poHeader.poDocTypeDescription'
  ],
  includeFieldsAirFreight: [
    'dpomGacHistoryData.goodsAtConsolidatorChangeTimestamp',
    'dpomGacHistoryData.previousGoodsAtConsolidatorDate',
    'dpomGacHistoryData.previousGoodsAtConsolidatorReasonCode',
    'dpomGacHistoryData.previousGoodsAtConsolidatorReasonDescription',
    'dpomGacHistoryData.goodsAtConsolidatorDate',
    'dpomGacHistoryData.goodsAtConsolidatorReasonCode',
    'dpomGacHistoryData.goodsAtConsolidatorReasonDescription',
    'dpomGacHistoryData.liability',
    'dpomGacHistoryData.nikeDelay',
    'dpomGacHistoryData.factoryDelay',
    'dpomGacHistoryData.delay',
    'dpomAirFreight.faf',
    'dpomAirFreight.naf'
  ],
  includeFieldsGACHistorySubTable: [
    'dpomGacHistoryData.goodsAtConsolidatorChangeTimestamp',
    'dpomGacHistoryData.previousGoodsAtConsolidatorDate',
    'dpomGacHistoryData.previousGoodsAtConsolidatorReasonCode',
    'dpomGacHistoryData.previousGoodsAtConsolidatorReasonDescription',
    'dpomGacHistoryData.previousGrcUseCaseCode',
    'dpomGacHistoryData.previousGrcUseCaseDescription',
    'dpomGacHistoryData.goodsAtConsolidatorDate',
    'dpomGacHistoryData.goodsAtConsolidatorReasonCode',
    'dpomGacHistoryData.goodsAtConsolidatorReasonDescription',
    'dpomGacHistoryData.grcUseCaseCode',
    'dpomGacHistoryData.grcUseCaseDescription',
    'dpomGacHistoryData.liability',
    'dpomGacHistoryData.nikeDelay',
    'dpomGacHistoryData.factoryDelay',
    'dpomGacHistoryData.delay'
  ],
  userGroups: {
    pmo: USERGROUP.PMO,
    geo: USERGROUP.WHQ,
    factory: USERGROUP.FACTORY,
    infoTech: USERGROUP.INFOTECH,
    infoTechSecurity: USERGROUP.INFOTECH_SECURITY,
    pmoSecurity: USERGROUP.PMO_SECURITY,
    bpmSecurity: USERGROUP.BPM_SECURITY
  },
  userGroupPrecedence: ALLOWED_USER_GROUP,
  showPMOSettings: true,
  showBPMUser: true,
  showGEOUser: true,
  showGACusecase: true,
  showFeatureToggling: true,
  poSearchBackground: imagesPath.poSearchBackground.src,
  savedRecordsNo: 10,
  convoSearchCount: 100,
  airFrieghtAdjustmentCount: 50,
  convoSearchThresholdLimit: 100,
  convoSearchRowsPerPageOptions: [10, 25, 50],
  uploadMappingType: {
    0: gacEventHeader,
    1: gacEventHeader,
    2: gacEventHeader,
    3: poRejectionEventHeader
  },
  downloadMappingTypeForDev: {
    0: fileDownloadPoPdfEventHeader,
    1: fileDownloadSireEventHeader,
    2: fileDownloadReportEventHeader
  },
  downloadMappingType: {
    0: fileDownloadPoPdfEventHeader,
    1: fileDownloadSireEventHeader
  },
  configRowCount: 10,
  airFreightPRODDate: '2022-07-01',
  airFreightNONPRODDate: '2022-07-01',
  gacChangeHiddenCreateDate: '2023-02-14',
  gacChangeMinimumCreateDate: '2023-02-15',
  gacChangeMinimumCreateDateDesc: 'Wed Feb 15 2023 00:00:00 GMT+0530 (India Standard Time)',
  featureFlagFields: [
    'id',
    'featureName',
    'featureTag',
    'groups',
    'factoryAffiliations',
    'startTime',
    'endTime',
    'dependencyFeatures',
    'lastUpdatedBy',
    'lastUpdatedAt',
    'versionType'
  ]
};

export default appConfig;
